import { VStack } from '@chakra-ui/react';

import { ConfigForm } from './config-form';
import { UpdatePointsModal } from './update-points-modal';
import { UserPointsSearch } from './user-points-search';

export const OrgPointsContent: React.FC = () => {
  return (
    <VStack py={6} spacing={6}>
      <ConfigForm />
      <UserPointsSearch />
      <UpdatePointsModal />
    </VStack>
  );
};
