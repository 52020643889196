import { cloneElement } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation, useOpenClose } from 'hooks';
import * as authSelectors from 'state/auth/selectors';

import Button from 'components/Button';

import TransferModal from './TransferModal';
import locales from './i18n';
import { BANK_BOX_TYPES } from '../../constants';

interface TransferButtonProps {
  component?: React.ReactElement;
  data?: any;
  to: {
    type: string;
    id: string | number;
  };
  afterTransfer?: () => void;
  info?: string;
}

const TransferButton: React.FC<TransferButtonProps> = ({
  component, data, to, afterTransfer, info,
}) => {
  const { t } = useTranslation(locales);

  const myId = useSelector(authSelectors.selectId);

  const [isModalOpen, openModal, closeModal] = useOpenClose();

  const clickable = cloneElement(
    component || <Button>{t('Transfer')}</Button>,
    {
      onClick: openModal,
      disabled: !myId || (to.type === BANK_BOX_TYPES.USER && to.id === myId),
    },
  );

  return (
    <>
      {clickable}

      {/* Modal */}
      {isModalOpen && (
        <TransferModal
          to={to}
          data={data}
          close={closeModal}
          afterTransfer={afterTransfer}
          info={info}
        />
      )}
    </>
  );
};

export default TransferButton;
