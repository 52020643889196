import { createSelector } from 'reselect';
import moment from 'moment';

import * as userSelectors from 'state/users/selectors';

import { GENDERS } from '../../constants';

// Input selectors

export const selectMe = state => state.auth.me;

export const selecOrganizations = state => state.auth.organizations;

export const selectChatRequests = state => state.auth.chatRequests;

export const isLoggingIn = state => state.auth.loggingIn;

export const isAutoLoggingIn = state => state.auth.loginAuto;

export const getLoginError = state => state.auth.loginError;

export const selectUserLists = state => (state.auth.me ? state.auth.me.lists : []);

export const selectHasEmailBounced = state => state.auth.me.emailBounced;

//

export const loggedIn = createSelector(
  selectMe,
  me => !!me,
);

export const selectJWT = createSelector(
  selectMe,
  me => (me ? me.jwt : null),
);

export const selectId = createSelector(
  selectMe,
  me => (me ? me.id : null),
);

export const selectUsername = createSelector(
  selectMe,
  me => (me ? me.username : null),
);

export const selectFollowingUsers = createSelector(
  selectMe,
  me => (me ? me.following_users : null),
);

export const selectPronoun = createSelector(
  selectMe,
  me => (me ? me.pronoun : null),
);

export const selectUserId = createSelector(
  selectMe,
  me => (me ? me.id : null),
);

export const selectDisplayname = createSelector(
  selectMe,
  me => (me ? me.displayname : null),
);

export const isOrganization = createSelector(
  selectMe,
  me => me?.isOrganization,
);

export const canReportPublications = createSelector(
  selectMe,
  me => !!me?.canReportPublications,
);

export const selectEmail = createSelector(
  selectMe,
  me => (me ? me.email : null),
);

export const selectStarMode = createSelector(
  selectMe,
  me => (me ? me.starMode : null),
);

export const selectFollowPublicationOnComment = createSelector(
  selectMe,
  me => (me ? me.followPublicationOnComment : null),
);

export const selectCountry = createSelector(
  selectMe,
  me => (me ? me.country : null),
);

export const selectRegion = createSelector(
  selectMe,
  me => (me ? me.region : null),
);

export const selectCity = createSelector(
  selectMe,
  me => (me ? me.city : null),
);

export const selectCoordinates = createSelector(
  selectMe,
  me => (me ? me.geo?.subdivision?.latLng : null),
);

export const selectGender = createSelector(
  selectMe,
  me => (me ? me.gender : null),
);

export const selectBirthdate = createSelector(
  selectMe,
  me => (me ? me.birthdate : null),
);

export const selectJob = createSelector(
  selectMe,
  me => ((me && me.job) ? me.job : ''),
);

export const selectAboutMe = createSelector(
  selectMe,
  me => ((me && me.aboutMe) ? me.aboutMe : ''),
);

export const selectTags = createSelector(
  selectMe,
  me => me.tags,
);

export const selectRelationships = createSelector(
  selectMe,
  me => me.relationships,
);

export const isConfirmed = createSelector(
  selectMe,
  me => me && me.emailConfirmed,
);

export const locationMissing = createSelector(
  selectMe,
  me => !me.country && !me.region,
);

export const selectFollowingUserIds = createSelector(
  userSelectors.selectOnline,
  selectFollowingUsers,
  (online, followingUsers) => {
    const isOnline = userId => online.includes(userId);

    return followingUsers
      .sort((a, b) => {
        if (isOnline(a) && !isOnline(b)) return -1;
        if (isOnline(b) && !isOnline(a)) return 1;

        return b - a;
      });
  },
);

export const selectAmIFollowing = createSelector(
  selectFollowingUsers,
  (_, userId) => userId,
  (followingUsers, userId) => (followingUsers ? followingUsers.includes(userId) : null),
);

export const selectUserList = createSelector(
  selectUserLists,
  (_, listId) => listId,
  (lists, listId) => lists.find(l => l.id === listId),
);

export const selectExistsFollowList = createSelector(
  selectUserLists,
  (_, listId) => listId,
  (lists, listId) => lists.findIndex(l => l.id === listId) > -1,
);

export const selectUserListName = createSelector(
  selectUserList,
  list => (list ? list.name : null),
);

export const selectUserListUsers = createSelector(
  selectUserList,
  list => (list ? list.users : null),
);

export const selectInListsIds = createSelector(
  selectUserLists,
  (_, userId) => userId,
  (lists, userId) => lists
    .filter(l => l.users.some(uId => uId === userId))
    .map(l => l.id),
);

export const selectDoIKnow = createSelector(
  selectMe,
  (_, userId) => userId,
  (me, userId) => me.knowing.includes(userId),
);

export const selectIsBlocked = createSelector(
  selectMe,
  (_, userId) => userId,
  (me, userId) => me.blocks.includes(userId),
);

export const selectBlockedUsers = createSelector(
  selectMe,
  (me) => me.blocks,
);

export const selectIsEmailConfirmed = createSelector(
  selectMe,
  me => me && me.emailConfirmed,
);

export const selectNiches = createSelector(
  selectMe,
  (me) => {
    if (!me) return ['Lesb', 'Gay', 'Maledom', 'Femdom'];

    const { gender = '', tags = [] } = me;
    const niches = [];

    if (gender === GENDERS.MALE.label || gender.includes('MALE')) {
      if (tags.some(tag => ['HETEROSEXUAL', 'HETEROFLEXIBLE'].includes(tag))) {
        niches.push('Lesb');

        if (tags.some(tag => ['SUBMISIVE', 'SPANKEE', 'MASOCHIST', 'BOTTOM', 'FEMDOM'].includes(tag))) {
          niches.push('Femdom');
        } else if (tags.some(tag => ['DOMINANT', 'SPANKER', 'SADIST', 'TOP'].includes(tag))) {
          niches.push('Maledom');
        } else {
          niches.push('Femdom');
          niches.push('Maledom');
        }
      } else if (tags.some(tag => ['HOMOSEXUAL', 'GAY', 'FAG', 'QUEER'].includes(tag))) {
        niches.push('Gay');
      } else {
        niches.push('Gay');
        niches.push('Lesb');

        if (tags.some(tag => ['SUBMISIVE', 'SPANKEE', 'MASOCHIST', 'BOTTOM'].includes(tag))) {
          niches.push('Femdom');
        } else if (tags.some(tag => ['DOMINANT', 'SPANKER', 'SADIST', 'TOP'].includes(tag))) {
          niches.push('Maledom');
        } else {
          niches.push('Femdom');
          niches.push('Maledom');
        }
      }
    } else if (gender.includes('FEMALE') || gender.includes('WOMAN')) {
      if (tags.some(tag => ['HETEROSEXUAL', 'HETEROFLEXIBLE'].includes(tag))) {
        if (tags.some(tag => ['SUBMISIVE', 'SPANKEE', 'MASOCHIST', 'BOTTOM'].includes(tag))) {
          niches.push('Maledom');
        } else if (tags.some(tag => ['DOMINANT', 'SPANKER', 'SADIST', 'TOP'].includes(tag))) {
          niches.push('Femdom');
        } else {
          niches.push('Femdom');
          niches.push('Maledom');
        }
      } else if (tags.some(tag => ['HOMOSEXUAL', 'HOMOFLEXIBLE', 'LESBIAN', 'DYKE'].includes(tag))) {
        niches.push('Lesb');
      } else {
        niches.push('Lesb');

        if (tags.some(tag => ['SUBMISIVE', 'SPANKEE', 'MASOCHIST', 'BOTTOM'].includes(tag))) {
          niches.push('Maledom');
        } else if (tags.some(tag => ['DOMINANT', 'SPANKER', 'SADIST', 'TOP'].includes(tag))) {
          niches.push('Femdom');
        } else {
          niches.push('Femdom');
          niches.push('Maledom');
        }
      }
    } else {
      niches.push('Lesb');
      niches.push('Gay');
      niches.push('Maledom');
      niches.push('Femdom');
    }

    return niches;
  },
);

export const selectChecklistPrivacy = createSelector(
  selectMe,
  me => me.checklist.privacy,
);

export const selectChecklistListIds = createSelector(
  selectMe,
  me => me.checklist.lists,
);

export const getOnboardingPresentation = createSelector(
  selectMe,
  (me) => {
    if (!me) return null;
    if (moment().diff(me.regdate, 'days') > 7) return false;
    return me.onboarding && me.onboarding.presentation;
  },
);

export const getOnboardingChatRequest = createSelector(
  selectMe,
  (me) => {
    if (!me) return null;
    return me.onboarding && me.onboarding.chatRequest;
  },
);

export const getSades = createSelector(
  selectMe,
  me => Math.round((me?.sades || 0) * 100) / 100,
);

export const tagsAvailable = createSelector(
  selectMe,
  me => me?.tagsDescription?.available || 2,
);

export const tagsPurchased = createSelector(
  selectMe,
  me => me?.tagsDescription?.purchased || [],
);

export const getRequestsLeft = createSelector(
  selectChatRequests,
  (requests) => {
    if (!requests) return null;
    const total = requests.purchased.total + requests.free.total;
    const used = requests.purchased.used + requests.free.used;
    return total - used;
  },
);

export const getOrganizationTokens = createSelector(
  selectMe,
  me => me?.organizationTokens || [],
);

export const getOrganizationAdmins = createSelector(
  selectMe,
  me => me?.organizationAdmins || [],
);

export const selectOrgPointsConfig = createSelector(
  selectMe,
  me => me?.organizationPoints || {},
);

export const getMercadoPagoPublicKey = createSelector(
  selectMe,
  me => me?.marketplace?.mercadopago?.publicKey || null,
);

export const hasLovense = createSelector(
  selectMe,
  me => (me?.connections?.lovense || []).length > 0,
);
