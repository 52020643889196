import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  dialog: {
    marginTop: { base: 'auto', md: 16 },
    marginBottom: { base: 0, md: 16 },
    borderBottomRadius: { base: 0, md: 'md' },
    paddingBottom: { base: 8, md: 4 },
  },
  footer: {
    marginBottom: { base: 4, md: 0 },
  },
});

export default defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'xl',
  },
});
