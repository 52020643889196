export const SITE_URL = 'https://mazmo.net';
export const API_URL = process.env.REACT_APP_API_URL || 'https://next.mazmoapi.net';
export const EMAILS = {
  MAIN: 'hello@mazmo.net',
  CONFIRMATIONS: 'confirmations@support.mazmo.net',
  SALES: 'ads@mazmo.net',
};

export const DEFAULT_TITLE = 'Mazmo';
export const POST_TITLE = ` - ${DEFAULT_TITLE}`;

export const GIPHY_API_KEY = 'mvFSeyGlQEzhaIR9GZ0SrKLVj0MJLDMU';

export const GA_TRACKING_ID = 'G-8F45HL66NJ';
export const GMAPS_API_KEY = 'AIzaSyDOfi4bcnukLFyfJIto_jHdNNY-xkJz8ik';

export const META_DESCRIPTION = 'Red social de sexualidad y BDSM libre. Chat BDSM, Foros sado, masoquismo, fetish';
export const META_IMAGE = `${SITE_URL}/images/share.jpeg`;

export const FEED_TEMP_PATH = 'https://prod.mazmocdn.com/feed/temp';

export const PAYMENTS_INTEGRATION_URL = 'https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js';

export const THREADS_LIST_LIMIT = 30;
export const REPLIES_LIST_LIMIT = 30;
export const PUBLICATIONS_LIST_LIMIT = 30;
export const GUEST_MAX_THREADS = 10;

export const COMMUNITY_PRIVACIES = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};

export const CHANNEL_PRIVACIES = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};

export const PUBLICATION_PRIVACIES = {
  PUBLIC: 'PUBLIC',
  CONTACTS: 'CONTACTS',
  LISTS: 'LISTS',
};

// ORDER MATTTERS!! - Upper levels has privileges to its belows
export const MEMBERSHIP_ROLES = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  MODERATOR: 'MODERATOR',
  USER: 'USER',
};

export const CHANNEL_ROLES = {
  OWNER: 'OWNER',
  MOD: 'MOD',
  USER: 'USER',
};

export const GENDERS = {
  MALE: {
    label: 'MALE',
    defaultPronoun: 'MALE',
  },
  FEMALE: {
    label: 'FEMALE',
    defaultPronoun: 'FEMALE',
  },
  CROSSDRESSER: {
    label: 'CROSSDRESSER',
    defaultPronoun: 'NEUTRAL',
  },
  TRAVESTI: {
    label: 'TRAVESTI',
    defaultPronoun: 'NEUTRAL',
  },
  TRANS: {
    label: 'TRANS',
    defaultPronoun: 'NEUTRAL',
  },
  INTERSEX: {
    label: 'INTERSEX',
    defaultPronoun: 'NEUTRAL',
  },
  UNDEFINED: {
    label: 'UNDEFINED',
    defaultPronoun: 'NEUTRAL',
  },
  WOMAN_CIS: {
    label: 'WOMAN_CIS',
    defaultPronoun: 'FEMALE',
  },
  MALE_CIS: {
    label: 'MALE_CIS',
    defaultPronoun: 'FEMALE',
  },
  ANDROGYNOUS: {
    label: 'ANDROGYNOUS',
    defaultPronoun: 'NEUTRAL',
  },
  MALE_TRANS: {
    label: 'MALE_TRANS',
    defaultPronoun: 'MALE',
  },
  FEMALE_TRANS: {
    label: 'FEMALE_TRANS',
    defaultPronoun: 'NEUTRAL',
  },
  TRANSEXUAL: {
    label: 'TRANSEXUAL',
    defaultPronoun: 'NEUTRAL',
  },
  TRANSGENDER: {
    label: 'TRANSGENDER',
    defaultPronoun: 'NEUTRAL',
  },
  FEMALE_TRANSGENDER: {
    label: 'FEMALE_TRANSGENDER',
    defaultPronoun: 'FEMALE',
  },
  MALE_TRANSGENDER: {
    label: 'MALE_TRANSGENDER',
    defaultPronoun: 'MALE',
  },
  QUEER: {
    label: 'QUEER',
    defaultPronoun: 'NEUTRAL',
  },
  NEUTRAL: {
    label: 'NEUTRAL',
    defaultPronoun: 'NEUTRAL',
  },
  NOT_BINARY: {
    label: 'NOT_BINARY',
    defaultPronoun: 'NEUTRAL',
  },
};

export const USERS_PRONOUNS = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  NEUTRAL: 'NEUTRAL',
};

export const RELATIONSHIP_TYPES = {
  DOM: 'DOM',
  MASTER: 'MASTER',
  TUTOR: 'TUTOR',
  SPANKER: 'SPANKER',
  OWNER: 'OWNER',
  SWITCHER: 'SWITCHER',
  ALPHA: 'ALPHA',
  SUB: 'SUB',
  SLAVE: 'SLAVE',
  APPRENTICE: 'APPRENTICE',
  BITCH: 'BITCH',
  SPANKEE: 'SPANKEE',
  PET: 'PET',
  CO_SUB: 'CO_SUB',
  GAME_PARTNER: 'GAME_PARTNER',
  INTERN_SUB: 'INTERN_SUB',
  DADDY: 'DADDY',
  LITTLEBOY: 'LITTLEBOY',
  TOY: 'TOY',
};

export const SOCKET_ACTIONS = {
  COMMUNITIES: {
    COMMUNITY_CREATED: 'community:created',
    COMMUNITY_EDITED: 'community:edited',
    COMMUNITY_DELETED: 'community:deleted',

    MEMBERSHIP_CREATED: 'community:membership:created',
    MEMBERSHIP_APPROVED: 'community:membership:approved',
    MEMBERSHIP_DELETED: 'community:membership:deleted',

    THREAD_CREATED: 'community:thread:created',
    THREAD_EDITED: 'community:thread:edited',
    THREAD_DELETED: 'community:thread:deleted',
    THREAD_RESTORED: 'community:thread:restored',

    REPLY_CREATED: 'community:reply:created',
    REPLY_EDITED: 'community:reply:edited',
    REPLY_DELETED: 'community:reply:deleted',
    REPLY_RESTORED: 'community:reply:restored',

    COMMUNITY_JOINED: 'community:joined',
    COMMUNITY_LEFT: 'community:left',
  },

  MESSENGERS: {
    DM_CREATED: 'messengers:directmessage:created',
    DM_UPDATED: 'messengers:directmessage:updated',
    DM_REMOVED: 'messengers:directmessage:removed',
    MESSENGER_UPDATED: 'messengers:messenger:updated',
    MESSENGER_TYPING: 'messengers:messenger:typing',
  },

  CHANNELS: {
    CM_CREATED: 'chat:channel:message:created',
    CM_UPDATED: 'chat:channel:message:updated',
    CM_REMOVED: 'chat:channel:message:removed',
    CHANNEL_UPDATED: 'chat:channel:updated',
    CHANNEL_DELETED: 'chat:channel:deleted',
    CHANNEL_TYPING: 'chat:channel:typing',
    CHAT_EMBED_UPDATED: 'chat:embed:updated',
  },

  ALERTS: {
    ALERT_CREATED: 'alerts:created',
    ALERT_UPDATED: 'alerts:updated',
  },

  USERS: {
    ONLINE_LIST: 'users:online:list',
    ONLINE: 'users:online:new',
    OFFLINE: 'users:offline:new',
    BANNED: 'user:banned',
    PRIVATELY_UPDATED: 'users:updated:privately',
    REQUESTS_UPDATED: 'users:requests:updated',
  },

  FEED: {
    NEW_PUBLICATION_IN_FEED: 'feed:publication:added',
    PUBLICATION_UPDATED: 'feed:publication:updated',
    PUBLICATION_REMOVED: 'feed:publication:removed',
    POLL_UPDATED: 'feed:poll:udpdated',
  },

  EVENTS: {
    RSVP_CREATED: 'events:rsvp:created',
    RSVP_REMOVED: 'events:rsvp:removed',
  },

  BANK: {
    COUPON_CREATED: 'bank:coupon:created',
    COUPON_UPDATED: 'bank:coupon:updated',
  },
};

export const APP_NAMES = {
  web: 'mazmo-webapp',
};

export const SW = {
  NEW_AUTH: 'notifications/newAuth',
  NEW_THREADS: 'notifications/newThreads',
  NEW_REPLIES: 'notifications/newReplies',
};

export const ALERT_TYPES = {
  COMMUNITY_INVITE_CREATED: 'COMMUNITY_INVITE_CREATED',
  REACTION_REPLY_CREATED: 'REACTION_REPLY_CREATED',
  REACTION_THREAD_CREATED: 'REACTION_THREAD_CREATED',
  COMMUNITIES_THREAD_MENTION: 'COMMUNITIES_THREAD_MENTION',
  FEED_PUBLICATION_VOTE: 'FEED_PUBLICATION_VOTE',
  PUBLICATION_SINGLE_UNLOCKED: 'PUBLICATION_SINGLE_UNLOCKED',
  PUBLICATION_CROWD_UNLOCKED: 'PUBLICATION_CROWD_UNLOCKED',
  FEED_PUBLICATION_MODERATED: 'FEED_PUBLICATION_MODERATED',

  SADES_TRANSACTION: 'SADES_TRANSACTION',
  SADES_ASSIGNMENT: 'SADES_ASSIGNMENT',
  FOLLOW_CREATED: 'FOLLOW_CREATED',
  KNOW_CREATED: 'KNOW_CREATED',
  COMMENT_CREATED: 'COMMENT_CREATED',

  REACTION_COMMENT_CREATED: 'REACTION_COMMENT_CREATED',
  REACTION_PUBLICATION_CREATED: 'REACTION_PUBLICATION_CREATED',
  RELATIONSHIP_REQUESTED: 'RELATIONSHIP_REQUESTED',
  RELATIONSHIP_APPROVED: 'RELATIONSHIP_APPROVED',
  RELATIONSHIP_REJECTED: 'RELATIONSHIP_REJECTED',
  RELATIONSHIP_REMOVED: 'RELATIONSHIP_REMOVED',

  THREAD_AWARDED: 'THREAD_AWARDED',
  REPLY_AWARDED: 'REPLY_AWARDED',

  MESSENGER_APPROVED: 'MESSENGER_APPROVED',
  MESSENGER_REQUESTED: 'MESSENGER_REQUESTED',
  CHAT_CHANNEL_DELETED: 'CHAT_CHANNEL_DELETED',
  CHANNELINVITATION_CREATED: 'CHANNELINVITATION_CREATED',
  CHAT_REQUESTS_REFILLED: 'CHAT_REQUESTS_REFILLED',

  FEED_PUBLICATION_REACTED: 'FEED_PUBLICATION_REACTED',
  FEED_COMMENT_REACTED: 'FEED_COMMENT_REACTED',
  FEED_COMMENT_CREATED: 'FEED_COMMENT_CREATED',
  FEED_PUBLICATION_MENTION: 'FEED_PUBLICATION_MENTION',

  RSVP_CREATED: 'RSVP_CREATED',
  RSVP_BANNED: 'RSVP_BANNED',
  EVENT_INVITE: 'EVENT_INVITE',
  ENROLLMENT_APPROVED: 'ENROLLMENT_APPROVED',

  BANK_TRANSACTION_CREATED: 'BANK_TRANSACTION_CREATED',

  TAG_EXPIRED: 'TAG_EXPIRED',
  TAGS_GIFTED: 'TAGS_GIFTED',

  ORG_POINTS_TRANSFERRED: 'ORG_POINTS_TRANSFERRED',

  // Legacy
  PAGE_RECOMMENDED: 'PAGE_RECOMMENDED',
  NEW_KNOWER: 'NEW_KNOWER',
  PUBLICATION_COMMENTS: 'PUBLICATION_COMMENTS',
  SADES_TRANSFERED: 'SADES_TRANSFERED',
  NEW_FOLLOWER: 'NEW_FOLLOWER',
  PUBLICATION_REACTIONS: 'PUBLICATION_REACTIONS',
};

export const CHANNEL_MESSAGES_TYPES = {
  MESSAGE: 'MESSAGE',
  NOTICE: 'NOTICE',
  JOIN: 'JOIN',
  PART: 'PART',
  BAN: 'BAN',
  UNBAN: 'UNBAN',
};

export const CHAT_REJECT_REASON = {
  NOT_INTERESTED: 'NOT_INTERESTED',
  INAPPROPRIATE: 'INAPPROPRIATE',
  SPAM: 'SPAM',
  GATOR: 'GATOR',
};

export const PUBLICATION_TYPES = {
  TEXT: 'TEXT',
  MEDIA: 'MEDIA',
  GIF: 'GIF',
  LINK: 'LINK',
  POLL: 'POLL',
};

export const CM_AUTHOR_TYPES = {
  USER: 'USER',
  BOT: 'BOT',
  AUTORESPONDER: 'AUTORESPONDER',
};

export const REPORT_REASONS = {
  SPAM: 'SPAM',
  HACKED: 'HACKED',
  FALSE_IDENTITY: 'FALSE_IDENTITY',
  HATE: 'HATE',
  TOS_VIOLATION: 'TOS_VIOLATION',
};

export const PUBREPORT_REASONS = {
  SPAM: 'SPAM',
  VIOLENCE: 'VIOLENCE',
  HARASSMENT: 'HARASSMENT',
  HATE_SPEECH: 'HATE_SPEECH',
  ABUSE: 'ABUSE',
};

export const TAG_SLOT = { price: 10 };

export const USERTAGS = {
  // Orientation
  HETEROSEXUAL: { label: 'HETEROSEXUAL', price: 0, group: 'Orientation' },
  HOMOSEXUAL: { label: 'HOMOSEXUAL', price: 0, group: 'Orientation' },
  BISEXUAL: { label: 'BISEXUAL', price: 0, group: 'Orientation' },
  PANSEXUAL: { label: 'PANSEXUAL', price: 0, group: 'Orientation' },
  HETEROFLEXIBLE: { label: 'HETEROFLEXIBLE', price: 0, group: 'Orientation' },
  HOMOFLEXIBLE: { label: 'HOMOFLEXIBLE', price: 0, group: 'Orientation' },
  ASEXUAL: { label: 'ASEXUAL', price: 0, group: 'Orientation' },
  GAY: { label: 'GAY', price: 0, group: 'Orientation' },
  LESBIAN: { label: 'LESBIAN', price: 0, group: 'Orientation' },
  DYKE: { label: 'DYKE', price: 0, group: 'Orientation' },
  FLUCTUENT: { label: 'FLUCTUENT', price: 0, group: 'Orientation' },
  QUEER: { label: 'QUEER', price: 0, group: 'Orientation' },
  FAG: { label: 'FAG', price: 0, group: 'Orientation' },
  ALOSEXUAL: { label: 'ALOSEXUAL', price: 0, group: 'Orientation' },
  ROMANTIC: { label: 'ROMANTIC', price: 0, group: 'Orientation' },

  // Relationality
  MONOGAMOUS: { label: 'MONOGAMOUS', price: 0, group: 'Relationships' },
  POLIGAMOUS: { label: 'POLIGAMOUS', price: 0, group: 'Relationships' },
  NON_MONOGAMIST: { label: 'NON_MONOGAMIST', price: 0, group: 'Relationships' },
  MONOFLEX: { label: 'MONOFLEX', price: 0, group: 'Relationships' },
  POLYAMOROUS: { label: 'POLYAMOROUS', price: 0, group: 'Relationships' },
  SWINGER: { label: 'SWINGER', price: 0, group: 'Relationships' },
  UNICORN: { label: 'UNICORN', price: 0, group: 'Relationships' },
  ADULTERER: { label: 'ADULTERER', price: 0, group: 'Relationships' },
  CASTITY: { label: 'CASTITY', price: 0, group: 'Relationships' },
  ANARQUIST: { label: 'ANARQUIST', price: 0, group: 'Relationships' },

  // Status
  IN_A_RELATIONSHIP: { label: 'IN_A_RELATIONSHIP', price: 0, group: 'Status' },
  SINGLE: { label: 'SINGLE', price: 0, group: 'Status' },
  VIRGIN: { label: 'VIRGIN', price: 0, group: 'Status' },
  IN_SEARCH: { label: 'IN_SEARCH', price: 0, group: 'Status' },
  NOT_IN_SEARCH: { label: 'NOT_IN_SEARCH', price: 0, group: 'Status' },
  AVAILABLE: { label: 'AVAILABLE', price: 0, group: 'Status' },
  SEEKING_INFO: { label: 'SEEKING_INFO', price: 0, group: 'Status' },
  DND: { label: 'DND', price: 0, group: 'Status' },
  UNDER_APPROVAL: { label: 'UNDER_APPROVAL', price: 0, group: 'Status' },
  UNDER_CASTITY: { label: 'UNDER_CASTITY', price: 50, group: 'Status' },
  UNDER_TRAINING: { label: 'UNDER_TRAINING', price: 0, group: 'Status' },
  UNDER_PENITENCE: { label: 'UNDER_PENITENCE', price: 50, group: 'Status' },
  PUNISHED: { label: 'PUNISHED', price: 100, group: 'Status' },
  TRAINING: { label: 'TRAINING', price: 100, group: 'Status' },
  HUNTING: { label: 'HUNTING', price: 100, group: 'Status' },
  PARTYING: { label: 'PARTYING', price: 50, group: 'Status' },
  WAITING: { label: 'WAITING', price: 50, group: 'Status' },
  HOME_ALONE: { label: 'HOME_ALONE', price: 50, group: 'Status' },
  IN_CLASS: { label: 'IN_CLASS', price: 50, group: 'Status' },
  TEACHING: { label: 'TEACHING', price: 50, group: 'Status' },
  PROPERTY: { label: 'PROPERTY', price: 100, group: 'Status' },
  INITIATED: { label: 'INITIATED', price: 100, group: 'Status' },
  NON_INITIATED: { label: 'NON_INITIATED', price: 100, group: 'Status' },
  FOR_FREE: { label: 'FOR_FREE', price: 100, group: 'Status' },
  ASKING_FOR_IT: { label: 'ASKING_FOR_IT', price: 100, group: 'Status' },
  RESERVED: { label: 'RESERVED', price: 100, group: 'Status' },
  BEEING_A_BABE: { label: 'BEEING_A_BABE', price: 40, group: 'Status' },
  BEEING_A_CUDLEE: { label: 'BEEING_A_CUDLEE', price: 20, group: 'Status' },
  BEEING_A_CUDLER: { label: 'BEEING_A_CUDLER', price: 20, group: 'Status' },
  IN_HAREM: { label: 'IN_HAREM', price: 40, group: 'Status' },
  JUST_CYBER: { label: 'JUST_CYBER', price: 20, group: 'Status' },
  LOOKING_FOR_FRIENDS: { label: 'LOOKING_FOR_FRIENDS', price: 20, group: 'Status' },
  SEND_DICKPICS: { label: 'SEND_DICKPICS', price: 20, group: 'Status' },
  SEND_NUDES: { label: 'SEND_NUDES', price: 20, group: 'Status' },
  SEND_SADES: { label: 'SEND_SADES', price: 20, group: 'Status' },
  LOOKING_UNICORN: { label: 'LOOKING_UNICORN', price: 20, group: 'Status' },
  LOOKING_COUPLE: { label: 'LOOKING_COUPLE', price: 20, group: 'Status' },
  IN_USE: { label: 'IN_USE', price: 20, group: 'Status' },
  IN_GROUP: { label: 'IN_GROUP', price: 20, group: 'Status' },

  // Vincularity
  SAPIOSEXUAL: { label: 'SAPIOSEXUAL', price: 0, group: 'Vincularity' },
  DEMISEXUAL: { label: 'DEMISEXUAL', price: 0, group: 'Vincularity' },
  GREY_ASEXUAL: { label: 'GREY_ASEXUAL', price: 0, group: 'Vincularity' },
  AROMANTIC: { label: 'AROMANTIC', price: 0, group: 'Vincularity' },
  HEDONIST: { label: 'HEDONIST', price: 0, group: 'Vincularity' },
  ANTISOCIAL: { label: 'ANTISOCIAL', price: 20, group: 'Vincularity' },
  SOCIABLE: { label: 'SOCIABLE', price: 40, group: 'Vincularity' },

  // Clan
  SPANKO: { label: 'SPANKO', price: 0, group: 'Clan' },
  FEMDOM: { label: 'FEMDOM', price: 0, group: 'Clan' },
  MALEDOM: { label: 'MALEDOM', price: 0, group: 'Clan' },
  PROTOCOLAR: { label: 'PROTOCOLAR', price: 0, group: 'Clan' },
  TWENTIFOURSEVEN: { label: '24/7', price: 0, group: 'Clan' },
  DS: { label: 'D/S', price: 0, group: 'Clan' },
  SM: { label: 'S&M', price: 0, group: 'Clan' },
  SHIBARI: { label: 'SHIBARI', price: 0, group: 'Clan' },
  KINBAKU: { label: 'KINBAKU', price: 0, group: 'Clan' },
  LEATHER: { label: 'LEATHER', price: 0, group: 'Clan' },
  TANTRA: { label: 'TANTRA', price: 100, group: 'Clan' },
  PODOPHYLLUM: { label: 'PODOPHYLLUM', price: 0, group: 'Clan' },
  BEAR: { label: 'BEAR', price: 0, group: 'Clan' },
  GENTLE: { label: 'GENTLE', price: 0, group: 'Clan' },
  BBW: { label: 'BBW', price: 0, group: 'Clan' },
  MILF: { label: 'MILF', price: 0, group: 'Clan' },
  HUNTER: { label: 'HUNTER', price: 50, group: 'Clan' },
  HENTAI_LOVER: { label: 'HENTAI_LOVER', price: 20, group: 'Clan' },
  OLD_GUARD: { label: 'OLD_GUARD', price: 20, group: 'Clan' },
  OLD_SCHOOL: { label: 'OLD_SCHOOL', price: 40, group: 'Clan' },
  SADOSAUR: { label: 'SADOSAUR', price: 60, group: 'Clan' },
  TWINK: { label: 'TWINK', price: 40, group: 'Clan' },
  APRENTICE: { label: 'APRENTICE', price: 20, group: 'Clan' },
  MASSAGE_THERAPIST: { label: 'MASSAGE_THERAPIST', price: 20, group: 'Clan' },
  DEEPTHROAT: { label: 'DEEPTHROAT', price: 40, group: 'Clan' },
  WRESTLER: { label: 'WRESTLER', price: 20, group: 'Clan' },
  FURRY: { label: 'FURRY', price: 0, group: 'Clan' },
  LATEX_FETISH: { label: 'LATEX_FETISH', price: 20, group: 'Clan' },
  NUDIST: { label: 'NUDIST', price: 0, group: 'Clan' },
  WOLF: { label: 'WOLF', price: 40, group: 'Clan' },
  BUSTY: { label: 'BUSTY', price: 40, group: 'Clan' },

  // EPE role
  VANILLA: { label: 'VANILLA', price: 0, group: 'EPE role' },
  TOP: { label: 'TOP', price: 0, group: 'EPE role' },
  BOTTOM: { label: 'BOTTOM', price: 0, group: 'EPE role' },
  SWITCH: { label: 'SWITCH', price: 0, group: 'EPE role' },
  FETISHIST: { label: 'FETISHIST', price: 0, group: 'EPE role' },
  TFTB: { label: 'TFTB', price: 20, group: 'EPE role' },
  DEVOTE: { label: 'DEVOTE', price: 40, group: 'EPE Role' },
  MAIDEN: { label: 'MAIDEN', price: 20, group: 'EPE Role' },

  // Preference role
  DOMINANT: { label: 'DOMINANT', price: 0, group: 'Preference Role' },
  SUBMISIVE: { label: 'SUBMISIVE', price: 0, group: 'Preference Role' },
  SPANKER: { label: 'SPANKER', price: 0, group: 'Preference Role' },
  SPANKEE: { label: 'SPANKEE', price: 0, group: 'Preference Role' },
  SADIST: { label: 'SADIST', price: 0, group: 'Preference Role' },
  MASOCHIST: { label: 'MASOCHIST', price: 0, group: 'Preference Role' },
  SADOMASOCHIST: { label: 'SADOMASOCHIST', price: 0, group: 'Preference Role' },
  CURIOUS: { label: 'CURIOUS', price: 0, group: 'Preference Role' },
  LIBERTINE: { label: 'LIBERTINE', price: 0, group: 'Preference Role' },
  KINKSTER: { label: 'KINKSTER', price: 0, group: 'Preference Role' },
  EXHIBISIONIST: { label: 'EXHIBISIONIST', price: 0, group: 'Preference Role' },
  EXPERIMENTALIST: { label: 'EXPERIMENTALIST', price: 0, group: 'Preference Role' },
  VOYEUR: { label: 'VOYEUR', price: 0, group: 'Preference Role' },
  BONDAGER: { label: 'BONDAGER', price: 0, group: 'Preference Role' },
  AGEPLAYER: { label: 'AGEPLAYER', price: 0, group: 'Preference Role' },
  CROSSDRESSER: { label: 'CROSSDRESSER', price: 0, group: 'Preference Role' },
  SISSY: { label: 'SISSY', price: 0, group: 'Preference Role' },
  CAREGIVER: { label: 'CAREGIVER', price: 40, group: 'Preference Role' },
  DEGRADEE: { label: 'DEGRADEE', price: 0, group: 'Preference Role' },
  DEGRADER: { label: 'DEGRADER', price: 0, group: 'Preference Role' },
  FURNITURE: { label: 'FURNITURE', price: 40, group: 'Preference Role' },
  HETERA: { label: 'HETERA', price: 40, group: 'Preference Role' },
  MONK_NUN: { label: 'MONK_NUN', price: 20, group: 'Preference Role' },
  MORBID: { label: 'MORBID', price: 40, group: 'Preference Role' },
  NURSE: { label: 'NURSE', price: 20, group: 'Preference Role' },
  PRIMAL_PREDATOR: { label: 'PRIMAL_PREDATOR', price: 40, group: 'Preference Role' },
  PRIMAL_PREY: { label: 'PRIMAL_PREY', price: 20, group: 'Preference Role' },
  PROFESSOR: { label: 'PROFESSOR', price: 40, group: 'Preference Role' },
  SUB: { label: 'SUB', price: 0, group: 'Preference Role' },

  // Humilliation
  PUPPET: { label: 'PUPPET', price: 100, group: 'Humilliation' },
  RAGGED: { label: 'RAGGED', price: 100, group: 'Humilliation' },
  CLOWN: { label: 'CLOWN', price: 100, group: 'Humilliation' },
  WHORE: { label: 'WHORE', price: 0, group: 'Humilliation' },
  SMARTMOUTH: { label: 'SMARTMOUTH', price: 100, group: 'Humilliation' },
  TRASH: { label: 'TRASH', price: 100, group: 'Humilliation' },
  WORM: { label: 'WORM', price: 100, group: 'Humilliation' },
  USELESS: { label: 'USELESS', price: 100, group: 'Humilliation' },
  TOY: { label: 'TOY', price: 0, group: 'Humilliation' },
  THING: { label: 'THING', price: 100, group: 'Humilliation' },
  SPH: { label: 'SPH', price: 100, group: 'Humilliation' },
  NWCM: { label: 'NWCM', price: 100, group: 'Humilliation' },
  NMCW: { label: 'NMCW', price: 100, group: 'Humilliation' },
  COCK_TEASER: { label: 'COCK_TEASER', price: 40, group: 'Humilliation' },
  CUNT_LICKER: { label: 'CUNT_LICKER', price: 40, group: 'Humilliation' },
  DICK_SUCKER: { label: 'DICK_SUCKER', price: 40, group: 'Humilliation' },
  JERK: { label: 'JERK', price: 20, group: 'Humilliation' },
  PIG: { label: 'PIG', price: 40, group: 'Humilliation' },
  SMART_ASS: { label: 'SMART_ASS', price: 40, group: 'Humilliation' },
  ASHTRAY: { label: 'ASHTRAY', price: 20, group: 'Humilliation' },
  DIRTY: { label: 'DIRTY', price: 40, group: 'Humilliation' },
  SWALLOWER: { label: 'SWALLOWER', price: 100, group: 'Humilliation' },
  TOILET: { label: 'TOILET', price: 40, group: 'Humilliation' },
  PIGGY: { label: 'PIGGY', price: 40, group: 'Humilliation' },
  S_P_H: { label: 'S_P_H', price: 40, group: 'Humilliation' },
  ASS_KISSER: { label: 'ASS_KISSER', price: 40, group: 'Humilliation' },

  // Detailed Role
  MASTER: { label: 'MASTER', price: 0, group: 'Detailed Role' },
  SLAVE: { label: 'SLAVE', price: 0, group: 'Detailed Role' },
  ALPHA: { label: 'ALPHA', price: 100, group: 'Detailed Role' },
  APEX: { label: 'APEX', price: 100, group: 'Detailed Role' },
  GOR_KAJIR: { label: 'GOR_KAJIR', price: 50, group: 'Detailed Role' },
  MAID: { label: 'MAID', price: 50, group: 'Detailed Role' },
  SERVANT: { label: 'SERVANT', price: 50, group: 'Detailed Role' },
  PET: { label: 'PET', price: 0, group: 'Detailed Role' },
  RIGGER: { label: 'RIGGER', price: 100, group: 'Detailed Role' },
  BUNNY_ROPE: { label: 'BUNNY_ROPE', price: 50, group: 'Detailed Role' },
  ROLEPLAY: { label: 'ROLEPLAY', price: 0, group: 'Detailed Role' },
  BRAT: { label: 'BRAT', price: 50, group: 'Detailed Role' },
  LITTLE: { label: 'LITTLE', price: 0, group: 'Detailed Role' },
  GIVER: { label: 'GIVER', price: 100, group: 'Detailed Role' },
  SUGAR_GIVER: { label: 'SUGAR_GIVER', price: 200, group: 'Detailed Role' },
  SUGARBABY: { label: 'SUGARBABY', price: 100, group: 'Detailed Role' },
  CUCKOLDRY: { label: 'CUCKOLDRY', price: 0, group: 'Detailed Role' },
  CELIBATE: { label: 'CELIBATE', price: 0, group: 'Detailed Role' },
  TAMER: { label: 'TAMER', price: 50, group: 'Detailed Role' },
  PRIMAL: { label: 'PRIMAL', price: 0, group: 'Detailed Role' },
  ABDL: { label: 'ABDL', price: 0, group: 'Detailed Role' },
  RUBBERPEOPLE: { label: 'RUBBERPEOPLE', price: 50, group: 'Detailed Role' },
  TINY: { label: 'TINY', price: 50, group: 'Detailed Role' },
  BARBIE: { label: 'BARBIE', price: 50, group: 'Detailed Role' },
  BIMBO: { label: 'BIMBO', price: 50, group: 'Detailed Role' },
  COSPLAYER: { label: 'COSPLAYER', price: 50, group: 'Detailed Role' },
  DISCIPLINARIAN: { label: 'DISCIPLINARIAN', price: 100, group: 'Detailed Role' },
  FINDOM: { label: 'FINDOM', price: 100, group: 'Detailed Role' },
  FINSUB: { label: 'FINSUB', price: 100, group: 'Detailed Role' },
  CHILDING: { label: 'CHILDING', price: 100, group: 'Detailed Role' },
  YOUNGLING: { label: 'YOUNGLING', price: 100, group: 'Detailed Role' },
  OWNER: { label: 'OWNER', price: 100, group: 'Detailed Role' },
  PRODOM: { label: 'PRODOM', price: 100, group: 'Detailed Role' },
  PROSUB: { label: 'PROSUB', price: 100, group: 'Detailed Role' },
  KITTEN: { label: 'KITTEN', price: 0, group: 'Detailed Role' },
  PUPPY: { label: 'PUPPY', price: 0, group: 'Detailed Role' },
  TUTOR: { label: 'TUTOR', price: 50, group: 'Detailed Role' },
  MENTOR: { label: 'MENTOR', price: 50, group: 'Detailed Role' },
  ROOKIE: { label: 'ROOKIE', price: 0, group: 'Detailed Role' },
  CAT: { label: 'CAT', price: 50, group: 'Detailed Role' },
  DOMINATRIX: { label: 'DOMINATRIX', price: 50, group: 'Detailed Role' },
  JUNIOR: { label: 'JUNIOR', price: 50, group: 'Detailed Role' },
  PRINCESS: { label: 'PRINCESS', price: 100, group: 'Detailed Role' },
  SILLY: { label: 'SILLY', price: 50, group: 'Detailed Role' },
  EXPERIENCED: { label: 'EXPERIENCED', price: 100, group: 'Detailed Role' },
  GOD: { label: 'GOD', price: 100, group: 'Detailed Role' },
  SENIORITY_1: { label: 'SENIORITY_1', price: 100, group: 'Detailed Role' },
  SENIORITY_2: { label: 'SENIORITY_2', price: 100, group: 'Detailed Role' },
  SENIORITY_3: { label: 'SENIORITY_3', price: 100, group: 'Detailed Role' },
  VAMPIRE: { label: 'VAMPIRE', price: 100, group: 'Detailed Role' },
  COUGAR: { label: 'COUGAR', price: 40, group: 'Detailed Role' },
  DECORATION: { label: 'DECORATION', price: 40, group: 'Detailed Role' },
  HIEROPHILIC: { label: 'HIEROPHILIC', price: 20, group: 'Detailed Role' },
  RETIFIST: { label: 'RETIFIST', price: 40, group: 'Detailed Role' },
  SCAT_PLAYER: { label: 'SCAT_PLAYER', price: 20, group: 'Detailed Role' },
  TICKLEE: { label: 'TICKLEE', price: 40, group: 'Detailed Role' },
  TICKLER: { label: 'TICKLER', price: 40, group: 'Detailed Role' },
  UNDERWEAR_LOVER: { label: 'UNDERWEAR_LOVER', price: 40, group: 'Detailed Role' },
  DOG: { label: 'DOG', price: 20, group: 'Detailed Role' },
  DOGGY: { label: 'DOGGY', price: 20, group: 'Detailed Role' },
  SMALL_CAT: { label: 'SMALL_CAT', price: 20, group: 'Detailed Role' },
  DESPOT: { label: 'DESPOT', price: 40, group: 'Detailed Role' },
  DISCREET: { label: 'DISCREET', price: 20, group: 'Detailed Role' },
  HEARTBREAKER: { label: 'HEARTBREAKER', price: 40, group: 'Detailed Role' },
  VICIOUS: { label: 'VICIOUS', price: 40, group: 'Detailed Role' },
  PONY: { label: 'PONY', price: 40, group: 'Detailed Role' },
  CAPRICIOUS: { label: 'CAPRICIOUS', price: 40, group: 'Detailed Role' },
  MONARCH: { label: 'MONARCH', price: 200, group: 'Detailed Role' },
  ANGEL: { label: 'ANGEL', price: 100, group: 'Detailed Role' },
  SPOILED: { label: 'SPOILED', price: 40, group: 'Detailed Role' },
  ONANIST: { label: 'ONANIST', price: 20, group: 'Detailed Role' },
  BONDAGIST: { label: 'BONDAGIST', price: 40, group: 'Detailed Role' },
  LUDIC: { label: 'LUDIC', price: 20, group: 'Detailed Role' },
  CUDDLY: { label: 'CUDDLY', price: 20, group: 'Detailed Role' },
  INTENSE: { label: 'INTENSE', price: 20, group: 'Detailed Role' },
  ANAL: { label: 'ANAL', price: 20, group: 'Detailed Role' },
  SAINT: { label: 'SAINT', price: 40, group: 'Detailed Role' },
  BETA: { label: 'BETA', price: 40, group: 'Detailed Role' },
  GREEDY: { label: 'GREEDY', price: 40, group: 'Detailed Role' },
  INSATIABLE: { label: 'INSATIABLE', price: 40, group: 'Detailed Role' },
  CRYBABY: { label: 'CRYBABY', price: 40, group: 'Detailed Role' },
  SLUTTY: { label: 'SLUTTY', price: 100, group: 'Detailed Role' },
  OBJECT: { label: 'OBJECT', price: 40, group: 'Detailed Role' },
  DILF: { label: 'DILF', price: 100, group: 'Detailed Role' },
  PORN: { label: 'PORN', price: 40, group: 'Detailed Role' },
  ARTIST: { label: 'ARTIST', price: 20, group: 'Detailed Role' },
  LOW_PROFILE: { label: 'LOW_PROFILE', price: 20, group: 'Detailed Role' },
};

export const POLL_COLORS = {
  RED: '#ED4D3D',
  BLACK: '#000000',
  PINK: '#E91E63',
  PURPLE: '#9C27B0',
  VIOLET: '#673AB7',
  BLUE: '#3F51B5',
  NAVY_BLUE: '#2196F3',
  LIGHT_BLUE: '#03A9F4',
  TURQUOISE: '#00BCD4',
  AQUA: '#009688',
  GREEN: '#4CAF50',
  LIGHT_GREEN: '#8BC34A',
  OLIVE: '#CDDC39',
  YELLOW: '#FFEB3B',
  DARK_YELLOW: '#FFC107',
  ORANGE: '#FF9800',
  DARK_ORANGE: '#FF5722',
  BROWN: '#795548',
  GREY: '#9E9E9E',
  BLUE_GREY: '#607D8B',
};

export const BADGES = {
  ORGANIZER: { value: 10 },
  CONTRIBUTOR: { value: 10 },
  VINYL: { value: 100 },
};

export const BANK_BOX_TYPES = {
  BOT: 'BOT',
  USER: 'USER',
  CHANNEL: 'CHANNEL',
  COMMUNITY: 'COMMUNITY',
  CENTRAL_BANK: 'CENTRAL_BANK',
};

export const SADES_PACKAGES = [
  {
    name: 'SADES_PACK_50',
    amount: 50,
    pesos: 3200,
    usd: 4.99,
  },
  {
    highlighted: true,
    name: 'SADES_PACK_100',
    amount: 100,
    pesos: 5500,
    usd: 8.49,
  },
  {
    name: 'SADES_PACK_320',
    amount: 320,
    pesos: 12000,
    usd: 22.99,
  },
];
