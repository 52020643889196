import Api from 'state/api';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import { normalize } from 'normalizr';
import { batch } from 'react-redux';
import posthog from 'posthog-js';

import * as userActions from 'state/users/actions';
import * as alertActions from 'state/alerts/actions';
import * as channelActions from 'state/channels/actions';
import * as messengerActions from 'state/messengers/actions';

import authSchema, { relationships as relationshipsSchema } from './schema';

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  EMAIL_CONFIRMED,
  ADD_TO_FOLLOW_LIST,
  REMOVE_FROM_FOLLOW_LIST,
  UPDATE_FOLLOW_LIST,
  CREATE_FOLLOW_LIST,
  REMOVE_FOLLOW_LIST,
  ADD_TO_KNOWING,
  REMOVE_FROM_KNOWING,
  ADD_TO_BLOCKS,
  REMOVE_FROM_BLOCKS,
  UPDATE_ME,
  ADD_RELATIONSHIPS,
  REMOVE_RELATIONSHIP,
  APPROVE_RELATIONSHIP,
  UPDATE_CHECKLIST,
  DISMISS_ONBOARDING_CHATREQUEST,
  CHAT_REQUESTS,
  ADD_ORGANIZATION_TOKEN,
  REMOVE_ORGANIZATION_TOKEN,
  ORGANIZATIONS_LOAD,
  MARKETPLACE_MERCADOPAGO_PUBLICKEY,
} from './constants';

const thirdPartyUserIdentify = (user) => {
  try {
    posthog.identify(
      user.id,
      {
        username: user.username,
        displayname: user.displayname,
        avatar: user.avatar?.default,
      },
    );

    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user.id,
        username: user.username,
      });
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};

const parseRawData = (rawData) => {
  const normalizedData = normalize(rawData, authSchema);

  const me = normalizedData.entities.auth[normalizedData.result];

  const followingUserIds = new Set();
  Object.values(me.lists).forEach((fl) => {
    fl.users.forEach((uId) => {
      followingUserIds.add(uId);
    });
  });

  const data = {
    ...me,
    following_users: [...followingUserIds],
  };

  return { me: data, users: normalizedData.entities.users };
};

export const logout = () => async (dispatch) => {
  Api.unsetJWT();
  const { notificationToken } = window.localStorage;
  localStorage.removeItem('jwt');
  if (notificationToken !== null) {
    localStorage.removeItem('notificationToken');
  }

  posthog.reset();

  dispatch({ type: LOGOUT });

  dispatch(alertActions.wipe());
  dispatch(channelActions.wipe());
  dispatch(messengerActions.wipe());

  // REFACTOR - WIPE
  // memberships.dispatcher.wipe();
  // communities.dispatcher.wipe();
  // threads.dispatcher.wipe();
  // replies.dispatcher.wipe();
};

export const login = (
  username, password, reactivate = false, callback = null,
) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN, auto: false });
    const body = { username, password: password.trim() };
    if (reactivate) {
      body.reactivate = true;
    }

    const { data: rawData } = await Api.req.post('/users/login', body);
    const { me, users } = parseRawData(rawData);

    Api.setJWT(me.jwt);
    localStorage.setItem('jwt', me.jwt);

    batch(() => {
      dispatch({ type: LOGIN_SUCCESS, data: me });
      dispatch(userActions.add({
        ...users,
        [me.id]: me,
      }));
    });

    thirdPartyUserIdentify(me);

    ReactGA.event({
      category: 'Users',
      action: 'Login',
      label: me.username,
    });

    if (callback) callback();

    return me;
  } catch (err) {
    let error = err.message;
    if (err.response) {
      // eslint-disable-next-line
      error = err.response.data.message;
    } else if (err.request) {
      error = err.request;
    }

    dispatch(logout());
    dispatch({ type: LOGIN_FAIL, error });
    throw err;
  }
};

export const jwtLogin = jwt => async (dispatch) => {
  try {
    dispatch({ type: LOGIN, auto: true });
    Api.setJWT(jwt);
    const { data: rawData } = await Api.req.get('/users/me');
    const { me, users } = parseRawData(rawData);
    localStorage.setItem('jwt', me.jwt);

    batch(() => {
      dispatch({ type: LOGIN_SUCCESS, data: me });
      dispatch(userActions.add({
        ...users,
        [me.id]: me,
      }));
    });

    thirdPartyUserIdentify(me);
  } catch (error) {
    dispatch({ type: LOGOUT });
    dispatch({ type: LOGIN_FAIL, error });
  }
};

export const signup = params => async (dispatch) => {
  const { data: rawData } = await Api.req.post('/users', params);
  const { me } = parseRawData(rawData);

  Api.setJWT(me.jwt);
  localStorage.setItem('jwt', me.jwt);

  batch(() => {
    dispatch({ type: LOGIN_SUCCESS, data: me });
    dispatch(userActions.add({ [me.id]: me }));
  });

  ReactGA.event({
    category: 'Users',
    action: 'Register',
    label: me.username,
  });

  thirdPartyUserIdentify(me);

  return me;
};

export const recover = usernameOrEmail => async () => Api.req.post('/users/tokenactions/password-recover', { usernameOrEmail });

export const reset = (hash, password) => async (dispatch) => {
  const { data } = await Api.req.post(`/users/tokenactions/password-reset/${hash}`, { password: password.trim() });
  Api.setJWT(data.jwt);
  localStorage.setItem('jwt', data.jwt);
  dispatch({ type: LOGIN_SUCCESS, data });

  ReactGA.event({
    category: 'Users',
    action: 'Recover',
    label: data.username,
  });

  thirdPartyUserIdentify(data);
};

export const reactivate = (username, password) => async (dispatch) => {
  await dispatch(login(username.trim(), password.trim(), reactivate));

  ReactGA.event({
    category: 'Users',
    action: 'Reactivate',
    label: username,
  });
};

export const confirmEmail = hash => async (dispatch) => {
  await Api.req.post(`/users/tokenactions/confirmation/${hash}`);
  dispatch({ type: EMAIL_CONFIRMED });
};

export const addToList = (userId, listId) => async (dispatch) => {
  await Api.req.post(`/users/followlists/${listId}`, { userId });

  ReactGA.event({
    category: 'Users',
    action: 'Follow',
  });

  dispatch({
    type: ADD_TO_FOLLOW_LIST,
    listId,
    userId,
  });
};

export const removeFromList = (userId, listId) => async (dispatch) => {
  await Api.req.delete(`/users/followlists/${listId}/${userId}`);

  ReactGA.event({
    category: 'Users',
    action: 'Unfollow',
  });

  dispatch({
    type: REMOVE_FROM_FOLLOW_LIST,
    listId,
    userId,
  });
};

export const follow = userId => async (dispatch, getState) => {
  const listId = getState().auth.me.lists[0].id;

  await dispatch(addToList(userId, listId));
};

export const unfollow = userId => async (dispatch, getState) => {
  const promises = [];
  getState().auth.me.lists.forEach((list) => {
    if (list.users.includes(userId)) promises.push(dispatch(removeFromList(userId, list.id)));
  });

  await Promise.all(promises);
};

export const listsChange = (userId, lists) => async (dispatch, getState) => {
  const followLists = getState().auth.me.lists;
  const promises = followLists.map((list) => {
    const alreadyInList = list.users.some(id => id === userId);
    const shouldBeInList = lists.includes(list.id);

    if (alreadyInList === shouldBeInList) return null;
    if (shouldBeInList) {
      return dispatch(addToList(userId, list.id));
    }
    return dispatch(removeFromList(userId, list.id));
  });

  await Promise.all(promises);
};

export const know = userId => async (dispatch) => {
  await Api.req.post(`/users/${userId}/knows`);

  ReactGA.event({
    category: 'Users',
    action: 'Know',
  });

  dispatch({
    type: ADD_TO_KNOWING,
    userId,
  });
};

export const unknow = userId => async (dispatch) => {
  await Api.req.delete(`/users/${userId}/knows`);

  ReactGA.event({
    category: 'Users',
    action: 'Unknow',
  });

  dispatch({
    type: REMOVE_FROM_KNOWING,
    userId,
  });
};

export const block = blockedId => async (dispatch) => {
  await Api.req.post('/users/blocks', { blockedId });

  ReactGA.event({
    category: 'Users',
    action: 'Block',
  });

  dispatch({
    type: ADD_TO_BLOCKS,
    userId: blockedId,
  });
};

export const unblock = userId => async (dispatch) => {
  await Api.req.delete(`/users/blocks/${userId}`);

  ReactGA.event({
    category: 'Users',
    action: 'Unblock',
  });

  dispatch({
    type: REMOVE_FROM_BLOCKS,
    userId,
  });
};

export const report = (reportedId, reason, blockUser) => async (dispatch) => {
  await Api.req.post('/users/reports', { reportedId, reason });

  ReactGA.event({
    category: 'Users',
    action: 'Report Created',
  });

  if (blockUser) {
    dispatch(block(reportedId));
  }
};

export const getInFeedMuted = () => async () => {
  const { data } = await Api.req.get('/feed/users/muted');
  return data;
};

export const muteInFeed = userId => async () => {
  await Api.req.post('/feed/users/muted', { userId });

  ReactGA.event({
    category: 'Users',
    action: 'Mute',
    label: 'Feed',
  });
};
export const unmuteInFeed = userId => async () => {
  await Api.req.delete('/feed/users/muted', { data: { userId } });

  ReactGA.event({
    category: 'Users',
    action: 'Unmute',
    label: 'Feed',
  });
};

export const updateProfile = ({
  displayname,
  country,
  region,
  city,
  gender,
  pronoun,
  birthdate,
  job,
  aboutMe,
}) => async (dispatch) => {
  const { data } = await Api.req.put('/users/profile', {
    displayname,
    country,
    region,
    city,
    gender,
    pronoun,
    birthdate,
    job,
    aboutMe,
  });

  dispatch({ type: UPDATE_ME, data });
};

export const updateAvatar = blob => async (dispatch) => {
  const formData = new FormData();
  formData.append('avatar', blob, blob.name);

  const { data } = await Api.req.put('/users/avatar', formData, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
    timeout: 30000,
  });

  dispatch({ type: UPDATE_ME, data });
};

export const updateCover = blob => async (dispatch) => {
  const formData = new FormData();
  formData.append('cover', blob, blob.name);

  const { data } = await Api.req.put('/users/cover', formData, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
    timeout: 30000,
  });

  dispatch({ type: UPDATE_ME, data });
};

export const updateStarMode = active => async (dispatch) => {
  const { data } = await Api.req.put('/users/starMode', { starMode: active });

  dispatch({ type: UPDATE_ME, data });
};

export const updateFollowPublicationOnComment = active => async (dispatch) => {
  const { data } = await Api.req.put('/users/followPublicationOnComment', { followPublicationOnComment: active });

  dispatch({ type: UPDATE_ME, data });
};

export const updateOrganization = organization => async (dispatch) => {
  const { data } = await Api.req.put('/users/organization', { organization });

  dispatch({ type: UPDATE_ME, data });
};

export const updateOrgPointsConfig = (payload) => async (dispatch) => {
  const { data } = await Api.req.put('/users/organizationpoints', payload);

  dispatch({ type: UPDATE_ME, data });
};

export const ignoreOnboardingPresentation = () => async (dispatch) => {
  const { data } = await Api.req.put('/users/onboarding/presentation', { presentation: false });

  dispatch({ type: UPDATE_ME, data });
};

export const dismissOnboardingChatRequest = () => async (dispatch) => {
  Api.req.put('/users/onboarding/chatRequest', { chatRequest: false });

  dispatch({ type: DISMISS_ONBOARDING_CHATREQUEST });
};

export const updateTags = tags => async (dispatch) => {
  const { data } = await Api.req.put('/users/tags', { tags });

  dispatch({ type: UPDATE_ME, data });
};

export const addTag = (tag) => async (dispatch) => {
  const { data } = await Api.req.post('/users/tags', { tag });

  dispatch({ type: UPDATE_ME, data });
  return data;
};

export const removeTag = (tag) => async (dispatch) => {
  const { data } = await Api.req.delete(`/users/tags/${tag}`);

  dispatch({ type: UPDATE_ME, data });
  return data;
};

export const sortTags = (tags) => async (dispatch) => {
  const { data } = await Api.req.put('/users/tags/sort', { tags });

  dispatch({ type: UPDATE_ME, data });
  return data;
};

export const updatePassword = (newPassword) => async () => {
  await Api.req.put('/users/password', { newPassword: newPassword.trim() });
};

export const updateEmail = (password, email) => async (dispatch) => {
  const { data } = await Api.req.put('/users/email', { password, email });

  dispatch({ type: UPDATE_ME, data });
};

export const fetchRelationships = () => async (dispatch, getState) => {
  const { id } = getState().auth.me;
  const { data: rawData } = await Api.req.get(`/users/${id}/relationships`);

  const data = normalize(rawData, [relationshipsSchema]);

  dispatch(userActions.add(data.entities.users));

  const relationships = data.entities.relationships
    ? Object.values(data.entities.relationships).sort((a, b) => a.position - b.position)
    : [];

  dispatch({ type: UPDATE_ME, data: { relationships } });
};

export const addRelationship = (relatesTo, type) => async (dispatch, getState) => {
  const { id } = getState().auth.me;
  const { data: rawData } = await Api.req.post(`/users/${id}/relationships`, {
    relatesTo,
    type,
  });

  const data = normalize(rawData, relationshipsSchema);

  dispatch(userActions.add(data.entities.users));

  const relationships = Object.values(data.entities.relationships)
    .sort((a, b) => a.position - b.position);

  dispatch({ type: ADD_RELATIONSHIPS, relationships });
};

export const removeRelationship = (userId, relationshipId) => async (dispatch) => {
  await Api.req.delete(`/users/${userId}/relationships/${relationshipId}`);

  dispatch({ type: REMOVE_RELATIONSHIP, relationshipId });
};

export const approveRelationship = (userId, relationshipId) => async (dispatch) => {
  await Api.req.put(`/users/${userId}/relationships/${relationshipId}`);

  dispatch({ type: APPROVE_RELATIONSHIP, relationshipId });
};

export const createFollowList = name => async (dispatch) => {
  const { data } = await Api.req.post('/users/followlists', { name });

  dispatch({ type: CREATE_FOLLOW_LIST, data });
  return data;
};

export const renameFollowList = (listId, name) => async (dispatch) => {
  const { data } = await Api.req.put(`/users/followlists/${listId}/name`, { name });
  dispatch({ type: UPDATE_FOLLOW_LIST, listId, data });
};

export const removeFollowList = (listId, moveToListId) => async (dispatch) => {
  await Api.req.delete(`/users/followlists/${listId}`, { moveToListId });

  dispatch({ type: REMOVE_FOLLOW_LIST, listId, moveToListId });
};

// Could be removed
export const updateChecklist = (privacy, lists, selections) => async (dispatch) => {
  await Api.req.put('/users/checklist', {
    privacy,
    lists,
    selections,
  });

  dispatch({ type: UPDATE_CHECKLIST, privacy, lists });
};

export const updateChecklistPrivacy = (privacy, lists) => async (dispatch) => {
  await Api.req.put('/users/checklist/privacy', {
    privacy,
    lists,
  });

  dispatch({ type: UPDATE_CHECKLIST, privacy, lists });
};

export const suspendAccount = password => async (dispatch) => {
  await Api.req.delete('/users', { data: { password } });
  dispatch(logout());
  window.location.href = '/';
};

export const resendConfirmation = () => async () => Api.req.post('/users/tokenactions/confirmation-resend');

export const update = ({ user }) => (dispatch) => {
  dispatch({ type: UPDATE_ME, data: user });
};

export const loadChatRequests = data => (dispatch) => {
  dispatch({ type: CHAT_REQUESTS, data });
};

export const createOrganizationToken = name => async (dispatch) => {
  const { data } = await Api.req.post('/users/organizationtokens', { name });

  dispatch({ type: ADD_ORGANIZATION_TOKEN, data: data.token });
  return data.token.encoded;
};

export const removeOrganizationToken = id => async (dispatch) => {
  await Api.req.delete(`/users/organizationtokens/${id}`);

  dispatch({ type: REMOVE_ORGANIZATION_TOKEN, id });
};

export const fetchOrganizations = () => async (dispatch, getState) => {
  const { isOrganization } = getState().auth.me;

  if (isOrganization) {
    const userId = localStorage.getItem('userId');
    if (userId) {
      dispatch(userActions.add(parseInt(userId, 10)));
    }
  } else {
    const { data } = await Api.req.get('/users/organizations');
    const users = {};

    data.forEach((orga) => {
      users[orga.id] = orga;
    });
    dispatch(userActions.add(users));

    dispatch({ type: ORGANIZATIONS_LOAD, data: Object.keys(users).map(id => parseInt(id, 10)) });
  }
};

export const addOrganizationAdmin = userId => async (dispatch) => {
  const { data } = await Api.req.post('/users/organizations/admins', { userId });
  dispatch({ type: UPDATE_ME, data });
};

export const removeOrganizationAdmin = userId => async (dispatch) => {
  const { data } = await Api.req.delete(`/users/organizations/admins/${userId}`);
  dispatch({ type: UPDATE_ME, data });
};

export const getOrganizationAuth = orgaId => async () => {
  const { data } = await Api.req.get(`/users/organizations/auth/${orgaId}`);
  return data;
};

export const marketplaceMercadoPago = payload => async (dispatch) => {
  const { data } = await Api.req.post('/users/marketplace/mercadopago', payload);
  dispatch({ type: MARKETPLACE_MERCADOPAGO_PUBLICKEY, data });
};
