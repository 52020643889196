/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { ChakraProvider } from '@chakra-ui/react';
import posthog from 'posthog-js';

import useOnMount from 'hooks/useOnMount';
import * as authSelectors from 'state/auth/selectors';
import * as authActions from 'state/auth/actions';

import Loading from 'components/Loading';

import Profile from 'containers/Profiles';
import SiteLayout from 'containers/SiteLayout';
import Landing from 'containers/Landing';
import Login from 'containers/Login';
import Signup from 'containers/Signup';
import ForgotPassword from 'containers/ForgotPassword';
import ResetPassword from 'containers/ForgotPassword/Reset';
import Logout from 'containers/Logout';
import Home from 'containers/Home';
import Publication from 'containers/Home/Publication';
import NewPublication from 'containers/Home/New';
import InfoAbout from 'containers/Info/About';
import InfoTOS from 'containers/Info/TOS';
import ChatDashboard from 'containers/Chat';
import Unsubscribe from 'containers/User/Unsubscribe';
import Hashtags from 'containers/Home/Hashtags';
import EventAssistance from 'containers/Events/Assistance';
import KinkyTest from 'containers/User/KinkyTest';
import { OrgPoints } from 'containers/User/OrgPoints';

import UserSwitch from './UsersSwitch';
import CommunitiesSwitch from './CommunitiesSwitch';
import Coupons from './Coupons';
import BouncedAlert from './BouncedAlert';
import GlobalInformation from './GlobalInformation';
import UnauthedRoute from './UnauthedRoute';
import AuthedRoute from './AuthedRoute';
import Routines from './Routines';
import Sockets from './Sockets';
import EventsSwitch from './EventsSwitch';
import IntegrationsSwitch from './IntegrationsSwitch';
import LoadingPage from './LoadingPage';
import Analytics from './Analytics';
import Boundary from './Boundary';
import theme from '../../theme';
import { GA_TRACKING_ID } from '../../constants';
import './App.css';

smoothscroll.polyfill();

const App = () => {
  const dispatch = useDispatch();

  const userIsLoggedIn = useSelector(authSelectors.loggedIn);
  const isAutoLoggingIn = useSelector(authSelectors.isAutoLoggingIn);

  const [initialized, setInitialized] = useState(false);

  const { jwt } = window.localStorage;

  useOnMount(() => {
    if (jwt) {
      dispatch(authActions.jwtLogin(jwt));
    }

    ReactGA.initialize([
      {
        trackingId: GA_TRACKING_ID,
      },
    ]);


    posthog.init('phc_dKNiaoh3OsLrN1h6FY1q5RX8PsGMoxIo8TOhV0v4kXe', {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'always',
    });
    setInitialized(true);
  });

  return (
    <ChakraProvider theme={theme}>
      <Boundary>
        {!!jwt && <LoadingPage shouldUnmount={initialized && !isAutoLoggingIn} />}
        {initialized && !isAutoLoggingIn && (
          <Suspense fallback={<Loading />}>
            {userIsLoggedIn && (
              <>
                <Sockets />
                <Routines />
              </>
            )}
            <Router>
              <Analytics />
              {userIsLoggedIn && <GlobalInformation />}
              {userIsLoggedIn && <BouncedAlert />}
              {userIsLoggedIn && <Coupons />}

              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => (
                    !userIsLoggedIn
                      ? <Landing />
                      : <SiteLayout><Home /></SiteLayout>
                  )}
                />
                <AuthedRoute
                  path="/logout"
                  exact
                  component={Logout}
                />
                <UnauthedRoute
                  path="/login"
                  exact
                  component={Login}
                />
                <UnauthedRoute
                  path="/signup"
                  exact
                  component={Signup}
                />
                <UnauthedRoute
                  path="/forgot-password"
                  exact
                  component={ForgotPassword}
                />
                <UnauthedRoute
                  path="/password-reset/:hash"
                  exact
                  component={ResetPassword}
                />
                <Route
                  path="/unsubscribe/:key"
                  exact
                  component={Unsubscribe}
                />
                <Route path="/info/about" component={InfoAbout} />
                <Route path="/info/tos" component={InfoTOS} />
                <SiteLayout>
                  <Switch>
                    <AuthedRoute
                      path="/trending"
                      exact
                      component={Home}
                    />
                    <AuthedRoute
                      path="/media"
                      exact
                      component={Home}
                    />
                    <AuthedRoute
                      path="/hashtags/:hashtag"
                      exact
                      component={Hashtags}
                    />
                    <AuthedRoute
                      path="/publications/:publicationId"
                      exact
                      component={Publication}
                    />
                    <AuthedRoute
                      path="/new/:type"
                      exact
                      component={NewPublication}
                    />
                    <Route
                      path="/chat/:type?/:chatId?"
                      component={ChatDashboard}
                    />
                    <Route
                      path="/@:username([^/]+)"
                      component={Profile}
                    />
                    <AuthedRoute
                      path="/eventassistance/:eventId/:key"
                      component={EventAssistance}
                    />
                    <Route path="/integrations">
                      <IntegrationsSwitch />
                    </Route>
                    <Route path="/kinkytest" component={KinkyTest} />
                    <Route path="/orgpoints" component={OrgPoints} />
                    <Route path="/user">
                      <UserSwitch />
                    </Route>
                    <Route path="/events">
                      <EventsSwitch />
                    </Route>
                    <CommunitiesSwitch />
                  </Switch>
                </SiteLayout>
              </Switch>
            </Router>
          </Suspense>
        )}
      </Boundary>
    </ChakraProvider>
  );
};

export default Sentry.withProfiler(App);
