import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import UserDisplayName from 'components/UserDisplayName';
import UserAvatar from 'components/UserAvatar';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

interface Props {
  timestamp: string;
  read: boolean;
  fromUserId: number;
  description: string;
  amount: number;
  pointsName: string;
}

export const OrgPointsTransferred: React.FC<Props> = ({
  timestamp, read, fromUserId, description, amount, pointsName,
}) => {
  const { t } = useTranslation(locales);

  const username = useSelector(userSelectors.getUsername(fromUserId));
  const isLoaded = useSelector(userSelectors.isLoaded(fromUserId));

  const url = isLoaded ? `/@${username}` : undefined;

  return (
    <AlertContainer timestamp={timestamp} image={<UserAvatar userId={fromUserId} size="40px" showOnline={false} />} read={read} to={url}>
      <Trans t={t} ns="alerts" i18nKey={description ? 'orgPointsTransferred_description' : 'orgPointsTransferred'} values={{ amount: String(amount), pointsName, description }}>
        <strong><UserDisplayName userId={fromUserId} /></strong>
        {' sent you {{amount}} {{pointsName}}'}
        {description && (
          <>
            :
            {' '}
            <strong>{description}</strong>
          </>
        )}
      </Trans>
    </AlertContainer>
  );
};
