import {
  useEffect, useRef, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, HStack } from '@chakra-ui/react';

import colors from 'utils/css/colors';
import isMobile from 'utils/isMobile';
import { useTitle, useMetaRobots } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as appSelectors from 'state/app/selectors';
import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

import NotFound from 'containers/NotFound';
import ErrorState from 'components/ErrorState';
import Header from 'components/Header';
import Loading from 'components/Loading';
import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';
import UserFollowsMe from 'components/UserFollowsMe';
import {
  CardAccountDetails,
  Post,
  AccountGroup,
  OrderBoolAscendingVariant,
  Camera,
} from 'components/Icons';
import { FlexWrapper, FlexContainer } from 'components/FlexWrapper';
import { TabsList, TabsWrapper } from 'components/Tabs';
import BankNote from 'components/Icons/BankNote';

import {
  FlexInnerWrapperProfile,
  MobileCover,
  DesktopCover,
  CoverGradient,
  BasicInfoWrapper,
  BasicInfo,
  UserDescription,
  Content,
  ContentInner,
  Title,
  HeaderAvatar,
  BasicInfoDesktopRelativeWrapper,
} from './UI';
import Blocked from './Blocked';
import Suspended from './Suspended';
import Banned from './Banned';
import Unlogged from './Unlogged';
import Badges from './Badges';
import Actions from './Actions';
import GenderAgeLocation from './GenderAgeLocation';
import UserTags from './UserTags';
import Counters from './Counters';
import MainActionButtons from './Actions/MainActionButtons';

const getInitialTabIndex = (username) => {
  switch (window.location.pathname) {
    case `/@${username}/photos`:
      return 1;
    case `/@${username}/info`:
      return 2;
    case `/@${username}/communities`:
      return 3;
    case `/@${username}/checklist`:
    case `/@${username}/orgpoints`:
      return 4;
    case `/@${username}`:
    default:
      return 0;
  }
};

const allTabs = [
  { key: 'publications', icon: <Post color={colors.grey} /> },
  { key: 'photo', icon: <Camera color={colors.grey} /> },
  { key: 'details', icon: <CardAccountDetails color={colors.grey} /> },
  { key: 'communities', icon: <AccountGroup color={colors.grey} /> },
  { key: 'checklist', icon: <OrderBoolAscendingVariant color={colors.grey} /> },
  { key: 'orgpoints', icon: <BankNote color={colors.grey} /> },
];

const ProfileContent = ({ children }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const isLoggedIn = useSelector(authSelectors.loggedIn);
  const userId = useSelector(userSelectors.getByUsername(params.username));
  const isOrganization = useSelector(userSelectors.isOrganization(userId));
  const isOrgPointsEnabled = useSelector(userSelectors.isOrgPointsEnabled(userId));
  const displayname = useSelector(userSelectors.getDisplayName(userId));
  const cover = useSelector(userSelectors.getCover(userId));
  const isFullyLoaded = useSelector(userSelectors.isFullyLoaded(userId));
  const isBlockingMe = useSelector(userSelectors.isBlockingMe(userId));
  const isSuspended = useSelector(userSelectors.isSuspended(userId));
  const isBanned = useSelector(userSelectors.isBanned(userId));
  const historyCanGoBack = useSelector(appSelectors.selectHistoryCanGoBack);

  const contentElement = useRef(null);
  const [errorCode, setErrorCode] = useState(null);
  const [errorUserId, setErrorUserId] = useState(null);

  const [scrolled, setScrolled] = useState(false);
  const [showingHeader, setShowingHeader] = useState(false);

  const [tabIndex, setTabIndex] = useState(getInitialTabIndex(params.username));
  const onTabChange = useCallback((index) => {
    if (index === 0) history.replace(`/@${params.username}`);
    if (index === 1) history.replace(`/@${params.username}/photos`);
    if (index === 2) history.replace(`/@${params.username}/info`);
    if (index === 3) history.replace(`/@${params.username}/communities`);
    if (index === 4) {
      if (isOrgPointsEnabled) history.replace(`/@${params.username}/orgpoints`);
      else history.replace(`/@${params.username}/checklist`);
    }

    setTabIndex(index);

    window.scrollTo(0, contentElement.current.offsetTop);
  }, [history, params.username, isOrgPointsEnabled]);

  useTitle(displayname);
  useMetaRobots('noindex, follow');

  useEffect(() => {
    // Fix scroll position on mount
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        await dispatch(userActions.fetchByUsername(params.username, true));
        setTabIndex(getInitialTabIndex(params.username));
      } catch (error) {
        if (error.response?.data?.data?.userId) setErrorUserId(error.response.data.data.userId);
        setErrorCode(error.response ? error.response.status : 500);
      }
    };

    if (!isFullyLoaded) {
      fetch();
    }
  }, [isFullyLoaded, dispatch, params.username]);

  useEffect(() => {
    const vh = document.body.clientHeight;

    const scrollListener = () => {
      const st = window.scrollY;

      if (!scrolled && st > 32) {
        setScrolled(true);
      } else if (scrolled && st <= 32) {
        setScrolled(false);
      }

      const HEADER_OFFSET = 280;
      if (!showingHeader && st > (vh - HEADER_OFFSET)) {
        setShowingHeader(true);
      } else if (showingHeader && st <= (vh - HEADER_OFFSET)) {
        setShowingHeader(false);
      }
    };

    if (isMobile) {
      window.addEventListener('scroll', scrollListener);
    }

    return () => window.removeEventListener('scroll', scrollListener);
  }, [scrolled, showingHeader]);

  const renderTitle = useCallback(() => {
    if (!userId) return null;
    return <Title showingHeader={showingHeader}><UserDisplayName userId={userId} /></Title>;
  }, [userId, showingHeader]);

  const renderAvatar = useCallback(() => {
    if (!userId) return null;
    return (
      <HeaderAvatar showingHeader={showingHeader}>
        <UserAvatar userId={userId} onlineBorderColor="black" size="40px" />
      </HeaderAvatar>
    );
  }, [userId, showingHeader]);

  const renderActions = useCallback(() => (
    <Actions userId={userId} />
  ), [userId]);

  const onBackClick = useCallback(() => {
    if (historyCanGoBack) {
      history.goBack();
    } else {
      history.push('/');
    }
  }, [history, historyCanGoBack]);

  const headerBackground = showingHeader ? '#000000' : 'transparent';
  const tabs = useMemo(() => allTabs.filter((tab) => {
    if (tab.key === 'orgpoints') return isOrgPointsEnabled;
    return !isOrganization || tab.key !== 'checklist';
  }), [isOrganization, isOrgPointsEnabled]);

  if (errorCode === 404) return <NotFound />;
  if (errorCode === 403) return <Blocked userId={errorUserId} username={params.username} />;
  if (isSuspended) return <Suspended userId={userId} username={params.username} />;
  if (isBanned) return <Banned />;
  if (errorCode) return <ErrorState />;
  if (isBlockingMe) return <Blocked userId={userId} username={params.username} />;

  return (
    <FlexWrapper canOverflow>
      {!userId
        ? <Loading />
        : (
          <FlexInnerWrapperProfile>
            <Header
              renderTitle={renderTitle}
              backgroundColor={headerBackground}
              renderAvatar={renderAvatar}
              renderActions={renderActions}
              negativeBadge
              desktopAbsolute
              border="0"
              onBackClick={onBackClick}
            />

            <FlexContainer padding="0">
              <MobileCover cover={cover} />

              <BasicInfoDesktopRelativeWrapper>
                <DesktopCover cover={cover} />
                <CoverGradient />

                <BasicInfoWrapper>
                  <BasicInfo scrolled={scrolled} showingHeader={showingHeader}>
                    <UserAvatar userId={userId} size="120px" showOnline={false} />

                    <HStack spacing={4}>
                      <Box><UserDisplayName userId={userId} onlineIndicator /></Box>
                      <UserFollowsMe userId={userId} />
                    </HStack>

                    <MainActionButtons userId={userId} />

                    <Box display={{ base: 'block', md: 'none' }}>
                      <UserDescription>
                        <GenderAgeLocation userId={userId} />
                        <UserTags userId={userId} />
                        <Badges userId={userId} />
                        <Counters userId={userId} />
                      </UserDescription>
                    </Box>
                  </BasicInfo>
                </BasicInfoWrapper>

                <Box display={{ base: 'none', md: 'block' }}>
                  <UserDescription scrolled={scrolled}>
                    <GenderAgeLocation userId={userId} />
                    <UserTags userId={userId} />
                    <Badges userId={userId} />
                    <Counters userId={userId} />
                  </UserDescription>
                </Box>
              </BasicInfoDesktopRelativeWrapper>

              <Content ref={contentElement}>
                {isLoggedIn
                  ? (
                    <>
                      <TabsWrapper sticky>
                        <TabsList data={tabs} selected={tabIndex} onSelect={onTabChange} />
                      </TabsWrapper>

                      <ContentInner key={`profile-content-${userId}`}>
                        {children}
                      </ContentInner>
                    </>
                  ) : (
                    <Unlogged userId={userId} />
                  )
                }
              </Content>
            </FlexContainer>
          </FlexInnerWrapperProfile>
        )
      }
    </FlexWrapper>
  );
};

ProfileContent.propTypes = {
  children: PropTypes.node.isRequired,
};

ProfileContent.defaultProps = {
};

export default ProfileContent;
