import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Button,
  VStack,
  FormErrorMessage,
  Textarea,
  FormHelperText,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'hooks';
import useToast from 'hooks/useToast';
import * as authSelectors from 'state/auth/selectors';
import * as authActions from 'state/auth/actions';

import locales from './i18n';

const schema = z.object({
  enabled: z.boolean(),
  singularName: z.string().min(1, 'Requerido'),
  pluralName: z.string().min(1, 'Requerido'),
  description: z.string(),
  canBuyWithSades: z.boolean(),
  canTransferBetweenUsers: z.boolean(),
  sadesRate: z.number().min(1, 'Debe ser mayor a 0'),
});

export const ConfigForm: React.FC = () => {
  const { t } = useTranslation(locales);
  const toast = useToast();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const orgPointsConfig = useSelector(authSelectors.selectOrgPointsConfig);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: orgPointsConfig,
  });

  const canBuyWithSades = watch('canBuyWithSades');
  const pluralName = watch('pluralName');
  const sadesRate = watch('sadesRate');

  const onSubmitHandler = async (data: z.infer<typeof schema>) => {
    try {
      setIsSubmitting(true);
      await dispatch(authActions.updateOrgPointsConfig(data));
      toast.success(t('Points configuration updated successfully'));
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmitHandler)}>
      <VStack spacing={4} align="stretch">
        <FormControl display="flex" alignItems="center">
          <FormLabel mb="0">{t('Enable Points System')}</FormLabel>
          <Switch {...register('enabled')} />
        </FormControl>

        <FormControl isInvalid={!!errors.singularName}>
          <FormLabel>{t('Points Name (Singular)')}</FormLabel>
          <Input {...register('singularName')} />
          <FormErrorMessage>{errors.singularName?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.pluralName}>
          <FormLabel>{t('Points Name (Plural)')}</FormLabel>
          <Input {...register('pluralName')} />
          <FormErrorMessage>{errors.pluralName?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.description}>
          <FormLabel>{t('Description')}</FormLabel>
          <FormHelperText>
            {t(
              'This description will be shown to users to let them know what points are for',
            )}
          </FormHelperText>
          <Textarea {...register('description')} />
          <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
        </FormControl>

        <FormControl display="flex" alignItems="center">
          <FormLabel mb="0">{t('Allow Users to transfer Points between them')}</FormLabel>
          <Switch {...register('canTransferBetweenUsers')} />
        </FormControl>

        <FormControl display="flex" alignItems="center">
          <FormLabel mb="0">{t('Allow Users to Purchase Points with Sades')}</FormLabel>
          <Switch {...register('canBuyWithSades')} />
        </FormControl>

        <FormControl isInvalid={!!errors.sadesRate} isDisabled={!canBuyWithSades}>
          <FormLabel>{t('Sades Rate')}</FormLabel>
          <FormHelperText>
            {t(
              'Users will get {{sadesRate}} {{pluralName}} for every Sade they transfer to the organization',
              {
                sadesRate,
                pluralName,
              },
            )}
          </FormHelperText>
          <Controller
            name="sadesRate"
            control={control}
            render={({ field: { ref, ...restField } }) => (
              <NumberInput
                {...restField}
                onChange={(newValue) => restField.onChange(+newValue)}
              >
                <NumberInputField ref={ref} name={restField.name} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          />
          <FormErrorMessage>{errors.sadesRate?.message}</FormErrorMessage>
        </FormControl>

        <Button
          type="submit"
          variant="primary"
          isLoading={isSubmitting}
        >
          {t('Save Configuration')}
        </Button>
      </VStack>
    </Box>
  );
};
