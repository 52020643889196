import React, { useState } from 'react';
import {
  Box,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  Heading,
  Divider,
} from '@chakra-ui/react';
import { Search } from 'lucide-react';
import { useSelector } from 'react-redux';

import useTranslation from 'hooks/useTranslation';
import api from 'state/api';
import * as authSelectors from 'state/auth/selectors';
import { UserSearchInput, User } from 'components/user-search-input';

import locales from './i18n';

export const UserPointsSearch: React.FC = () => {
  const { t } = useTranslation(locales);

  const orgPointsConfig = useSelector(authSelectors.selectOrgPointsConfig);

  const [selectedUser, setSelectedUser] = useState<{ user: User, points: number, loadingPoints: boolean } | null>(null);

  const handleUserSelect = async (user: User) => {
    setSelectedUser({ user, points: 0, loadingPoints: true });
    const { data } = await api.req.get(`/users/${user.id}/points`);
    setSelectedUser({ user, points: data.points, loadingPoints: false });
  };

  const handleUserRemove = () => {
    setSelectedUser(null);
  };

  return (
    <Box bg="white" p={6} borderRadius="lg" shadow="md" width="100%">
      <VStack spacing={6} align="stretch">
        <Box>
          <Heading size="sm" mb={4} display="flex" alignItems="center" gap={2}>
            <Search size={20} />
            {t('Search User Points')}
          </Heading>
          <UserSearchInput
            selectedUsers={selectedUser?.user ? [selectedUser.user] : []}
            onUserSelect={handleUserSelect}
            onUserRemove={handleUserRemove}
            placeholder={t('Search user by name or username...')}
            inputProps={{ width: 'full' }}
          />
        </Box>

        {selectedUser?.user && (
          <>
            <Divider />
            <Box>
              <Stat>
                <StatLabel color="gray.600">
                  {t('{{name}}\'s {{pluralName}} Balance', {
                    name: selectedUser.user.displayname,
                    pluralName: orgPointsConfig.pluralName,
                  })}
                </StatLabel>
                <StatNumber fontSize="4xl" fontWeight="bold" color="black">
                  {selectedUser.points}
                </StatNumber>
              </Stat>
            </Box>
          </>
        )}
      </VStack>
    </Box>
  );
};
