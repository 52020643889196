import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as authActions from 'state/auth/actions';

import Warning from 'components/Warning';
import Button from 'components/Button';

import locales from '../i18n';

const getPresentationsCommunity = (countryIsoCode) => {
  switch (countryIsoCode) {
    case 'ES': // Spain
      return '/+presentaciones-espana';
    case 'MX': // Mexico
      return '/+presentaciones-y-anuncios-de-mexico';
    case 'CL': // Chile
      return '/+presentaciones-y-anuncios-en-chile';
    default:
      return '/+presentaciones-y-anuncios';
  }
};

const Presentation = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const displayname = useSelector(authSelectors.selectDisplayname);
  const country = useSelector(authSelectors.selectCountry);

  const [ignoring, setIgnoring] = useState(false);

  const ignore = useCallback(() => {
    setIgnoring(true);
    dispatch(authActions.ignoreOnboardingPresentation(false));
  }, [dispatch]);

  if (ignoring) return null;

  return (
    <Warning fullMargin>
      <p>
        {t('It seems that you didn\'t do a presentation in the Communities yet!')}
      </p>
      <p>{t('Creating a presentation thread is the best way to make yourself visible to the community and start interacting with others.')}</p>

      <div className="actions">
        <Button to={`${getPresentationsCommunity(country?.isoCode)}/new?autofix=false&template=presentacion&title=Presentación de ${displayname}`}>{t('Make a presentation')}</Button>
        <Button className="empty" onClick={ignore}>{t('Ignore')}</Button>
      </div>
    </Warning>
  );
};

Presentation.propTypes = {
};

Presentation.defaultProps = {
};

export default Presentation;
