import {
  Button, ModalContent, Modal, ModalOverlay, useDisclosure, ModalHeader,
  ModalBody, FormLabel,
  NumberIncrementStepper,
  NumberInputStepper,
  NumberInputField,
  NumberInput,
  NumberDecrementStepper,
  FormErrorMessage,
  FormControl,
  Textarea,
  ButtonGroup,
  VStack,
  Box,
  ModalCloseButton,
} from '@chakra-ui/react';
import { Send } from 'lucide-react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';

import api from 'state/api';
import { useTranslation } from 'hooks';
import useToast from 'hooks/useToast';

import { User, UserSearchInput } from 'components/user-search-input';

import locales from '../i18n';

interface TransferPointsProps {
  pluralName: string;
  organizationId: string;
  onSuccess?: () => void;
}

const formSchema = z.object({
  amount: z.number().min(1),
  user: z.custom<User>((val): val is User => val !== null && val !== undefined && typeof val === 'object' && 'id' in val).refine((val) => val !== null, { message: 'User is required' }),
  description: z.string().optional(),
  organizationId: z.string(),
});

export const TransferPoints: React.FC<TransferPointsProps> = ({
  pluralName,
  organizationId,
  onSuccess,
}) => {
  const { t } = useTranslation(locales);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    handleSubmit,
    setValue,
    register,
    unregister,
    watch,
    control,
    formState: { isSubmitting, errors },
    reset,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      amount: 1,
      description: '',
      organizationId,
    },
  });

  const selectedUser = watch('user');

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    try {
      const payload = {
        amount: data.amount,
        toUserId: data.user.fullId,
        description: data.description,
        organizationId: data.organizationId,
      };

      await api.req.post('/users/orgpoints/transfer', payload);
      toast.success(t('The {{pluralName}} has been transferred to the user', { pluralName }));
      onClose();
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <Button leftIcon={<Send size={18} />} onClick={onOpen}>{t('Transfer')}</Button>

      <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={!isSubmitting} closeOnOverlayClick={!isSubmitting}>
        <ModalOverlay />
        {!isSubmitting && <ModalCloseButton />}
        <ModalContent>
          <ModalHeader>{t('Transfer {{pluralName}}', { pluralName })}</ModalHeader>
          <ModalBody>
            <Box as="form" onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing={4} align="stretch">
                <FormControl isInvalid={!!errors.user}>
                  <FormLabel>{t('Select the user to transfer to')}</FormLabel>
                  <UserSearchInput
                    selectedUsers={selectedUser ? [selectedUser] : []}
                    onUserSelect={(val) => setValue('user', val)}
                    onUserRemove={() => unregister('user')}
                    multiple={false}
                  />
                  {errors.user && <FormErrorMessage>{errors.user.message}</FormErrorMessage>}
                </FormControl>

                <FormControl isInvalid={!!errors.amount}>
                  <FormLabel>{t('{{pluralName}} to transfer', { pluralName })}</FormLabel>
                  <Controller
                    name="amount"
                    control={control}
                    render={({ field: { ref, ...restField } }) => (
                      <NumberInput
                        defaultValue={1}
                        min={1}
                        step={1}
                        size="lg"
                        w={36}
                        mx="auto"
                        isDisabled={isSubmitting}
                        {...restField}
                        onChange={(newValue) => restField.onChange(+newValue)}
                      >
                        <NumberInputField ref={ref} name={restField.name} textAlign="center" fontWeight="bold" fontSize={24} />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                  />
                  {errors.amount && <FormErrorMessage>{errors.amount.message}</FormErrorMessage>}
                </FormControl>

                <FormControl>
                  <FormLabel>{t('Description')}</FormLabel>
                  <Textarea {...register('description')} />
                </FormControl>

                <ButtonGroup mt={4}>
                  <Button onClick={onClose}>{t('global:Cancel')}</Button>
                  <Button type="submit" variant="primary" isLoading={isSubmitting} leftIcon={<Send size={18} />} w="full">{t('Transfer')}</Button>
                </ButtonGroup>
              </VStack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
