import { FC } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Image, ImageProps } from '@chakra-ui/react';

import * as userSelectors from 'state/users/selectors';

interface Props extends ImageProps {
  userId: number;
}

const UserCountryFlag: FC<Props> = ({ userId, ...rest }) => {
  const user: any = useSelector(userSelectors.getById(userId), shallowEqual);

  if (!user || !user.country || !user.country.isoCode) return null;
  return (
    <Image
      className="country-flag"
      src={`https://raw.githubusercontent.com/hampusborgos/country-flags/ba2cf4101bf029d2ada26da2f95121de74581a4d/png100px/${user.country.isoCode.toLowerCase()}.png`}
      alt={user.country.name}
      title={user.country.name}
      {...rest}
    />
  );
};

export default UserCountryFlag;
