import {
  Avatar, Tag, TagLabel, TagCloseButton,
} from '@chakra-ui/react';

import { User } from 'components/user-search-input';

interface UserPillProps {
  user: User;
  onRemove?: (userId: string) => void;
}

export const UserPill: React.FC<UserPillProps> = ({ user, onRemove }) => {
  return (
    <Tag
      key={user.fullId}
      size="md"
      borderRadius="full"
      variant="solid"
      colorScheme="gray.800"
      bgColor="black"
    >
      <Avatar
        src={user.avatar.default}
        boxSize="1rem"
        name={user.displayname}
        ml={-1}
        mr={2}
      />
      <TagLabel>{user.displayname}</TagLabel>
      {onRemove && <TagCloseButton onClick={() => onRemove(user.fullId)} />}
    </Tag>
  );
};
