import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation, useTitle } from 'hooks';
import * as appActions from 'state/app/actions';
import * as authSelectors from 'state/auth/selectors';

import Sidebar from 'containers/Sidebar';
import Layout from 'components/Layout';
import { FlexWrapper, FlexContainer } from 'components/FlexWrapper';
import PageHeader from 'components/PageHeader';
import NotFound from 'containers/NotFound';

import { OrgPointsContent } from './content';
import locales from './i18n';

export const OrgPoints: React.FC = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  useTitle(t('Organization points'));

  const isOrganization = useSelector(authSelectors.isOrganization);

  useEffect(() => {
    dispatch(appActions.uiLeftColumn(true));
  }, [dispatch]);

  if (!isOrganization) {
    return <NotFound />;
  }

  return (
    <Layout columns={2} feed leftColumnOpen={false} rightColumnOpen={false}>
      {/* @ts-ignore */}
      <FlexWrapper canOverflow>
        {/* @ts-ignore */}
        <FlexContainer framed>
          <Box position="relative">
            <PageHeader title={t('Organization points')} />

            <OrgPointsContent />
          </Box>
        </FlexContainer>
      </FlexWrapper>

      <Sidebar />
    </Layout>
  );
};
