import { memo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box } from 'lucide-react';

import { useTranslation } from 'hooks';
import * as appSelectors from 'state/app/selectors';
import * as appActions from 'state/app/actions';
import * as authSelectors from 'state/auth/selectors';
import * as invoiceSelectors from 'state/invoices/selectors';

import Avatar from 'components/UserAvatar';
import Menu, { Item, Title, Separator } from 'components/Menu';
import Toggle from 'components/Toggle';
import LanguageSelector from 'components/LanguageSelector';
import Badge from 'components/Badge';
import {
  AccountCircle, AccountEdit, Notebook, AccountSupervisor, Flag,
  Contacts, ListStatus, Alerts, Robot, LogoutVariant, OfficeBuilding,
  Lock,
} from 'components/Icons';
import BankNote from 'components/Icons/BankNote';

import Invoices from './Invoices';
import Sades from './Sades';
import Coupons from './Coupons';
import CouponNotification from './CouponNotification';
import Organizations from './Organizations';
import BackToUser from './BackToUser';
import NavItem from '../Item';
import locales from '../../i18n';

const NavAvatar = styled.div`
  width: 40px;
  margin: 0 0 0 10px;
  cursor: pointer;
  position: relative;

  .sades-info {
    position: absolute;
    font-size: 10px;
    background-color: rgba(0, 0, 0, .5);
    font-weight: normal;
    padding: 2px 4px;
    border-radius: 4px;
    bottom: 4px;

    &:after {
      content: ' §';
    }
  }

  div.dd-item:not(.nav-dd-content) {
    min-height: 40px;

    a {
      line-height: 40px;
    }
  }

  @media(max-width: 767px) {
    width: 32px;

    .avatar {
      width: 32px;
      height: 32px;
      color: white;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
`;

const UserMenu = memo(() => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const loggedIn = useSelector(authSelectors.loggedIn);
  const meId = useSelector(authSelectors.selectId);
  const username = useSelector(authSelectors.selectUsername);
  const unpaidInvoicesCount = useSelector(invoiceSelectors.unpaidInvoicesCount);
  const sades = useSelector(authSelectors.getSades);
  const isOrganization = useSelector(authSelectors.isOrganization);

  const isSafeForWork = useSelector(appSelectors.selectIsSafeForWork);
  const [profileMenuOpened, setProfileMenuOpened] = useState(false);

  const openProfileMenu = useCallback(() => {
    setProfileMenuOpened(true);
  }, []);
  const closeProfileMenu = useCallback(() => {
    setProfileMenuOpened(false);
  }, []);
  const toggleSafeForWork = useCallback(() => {
    dispatch(appActions.toggleSafeForWork());
  }, [dispatch]);

  if (!loggedIn) return null;

  return (
    <NavItem onClick={openProfileMenu} pressed={profileMenuOpened}>
      <NavAvatar>
        <CouponNotification />
        <Avatar userId={meId} size="40px" showOnline={false} />
        <div className="sades-info">{sades.toFixed(1)}</div>

        <Menu
          maxHeight="auto"
          open={profileMenuOpened}
          onClose={closeProfileMenu}
          className="nav-dropdown"
          size={5}
        >
          <Title>{t('Profile')}</Title>
          <Item>
            <AccountCircle outline color="#6F6666" />
            <Link to={`/@${username}`}>{t('See my profile')}</Link>
          </Item>
          <Item>
            <AccountEdit color="#6F6666" />
            <Link to="/user/edit/profile">{t('Edit my profile')}</Link>
          </Item>
          <Sades />
          <Invoices />
          <Coupons />

          {isOrganization && (
            <Item>
              <BankNote color="#6F6666" />
              <Link to="/orgpoints">{t('Organization points')}</Link>
            </Item>
          )}

          <Title>{t('My stuff')}</Title>
          <Item>
            <Notebook outline color="#6F6666" />
            <Link to="/user/collections">{t('Collections')}</Link>
          </Item>
          <Item>
            <AccountSupervisor color="#6F6666" />
            <Link to="/user/relationships">{t('Relationships')}</Link>
          </Item>
          <Item>
            <Contacts outline color="#6F6666" />
            <Link to="/user/lists">{t('Contact lists')}</Link>
          </Item>
          <Item>
            <ListStatus color="#6F6666" />
            <Link to="/user/checklist">{t('Checklist')}</Link>
          </Item>
          <Item>
            <Alerts outline color="#6F6666" />
            <Link to="/user/notifications">{t('Notifications')}</Link>
          </Item>
          <Item>
            <Robot outline color="#6F6666" />
            <Link to="/user/bots">{t('My chat bots')}</Link>
          </Item>
          <Item>
            <Box color="#6F6666" />
            <Link to="/integrations/mercadopago">{t('My integrations')}</Link>
          </Item>
          <Item>
            <Lock color="#6F6666" />
            <Link to="/user/blocks">{t('My blocks')}</Link>
          </Item>

          <Title>{t('Visualization')}</Title>
          <Item>
            <OfficeBuilding outline color="#6F6666" />
            <Toggle label={t('Safe for work')} active={isSafeForWork} onChange={toggleSafeForWork} />
          </Item>
          <Item>
            <Flag outline color="#6F6666" />
            <LanguageSelector />
          </Item>

          <Separator />

          {isOrganization
            ? <BackToUser />
            : <Organizations />
          }

          <Item>
            <LogoutVariant />
            <Link to="/logout">{t('Logout')}</Link>
          </Item>
        </Menu>
        {unpaidInvoicesCount > 0 && <Badge value={unpaidInvoicesCount} />}
      </NavAvatar>
    </NavItem>
  );
});

export default UserMenu;
