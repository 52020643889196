const languages = {
  es: {
    flag: 'https://raw.githubusercontent.com/hampusborgos/country-flags/ba2cf4101bf029d2ada26da2f95121de74581a4d/png100px/mx.png',
    name: 'Español (Latino)',
  },
  'es-ES': {
    flag: 'https://raw.githubusercontent.com/hampusborgos/country-flags/ba2cf4101bf029d2ada26da2f95121de74581a4d/png100px/es.png',
    name: 'Español',
  },
  'es-AR': {
    flag: 'https://raw.githubusercontent.com/hampusborgos/country-flags/ba2cf4101bf029d2ada26da2f95121de74581a4d/png100px/ar.png',
    name: 'Español (Argentina)',
  },
};

export default languages;
