import { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Textarea,
  useDisclosure,
  ButtonProps,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useSelector } from 'react-redux';

import Api from 'state/api';
import { useTranslation } from 'hooks';
import useToast from 'hooks/useToast';
import * as authSelectors from 'state/auth/selectors';

import { UserSearchInput, User } from 'components/user-search-input';
import { UserPill } from 'components/user-pill';

import { UpdatePointsData } from './types';
import locales from './i18n';

const schema = z.object({
  userIds: z.array(z.string()).min(1, 'Select at least one user'),
  points: z.number().refine((val) => val !== 0, 'Points cannot be zero'),
  description: z.string().optional(),
});

interface UpdatePointsModalProps {
  buttonProps?: ButtonProps;
  buttonText?: string;
  fixedSelectedUsers?: User[];
  onSuccess?: () => void;
}

export const UpdatePointsModal: React.FC<UpdatePointsModalProps> = ({
  buttonProps,
  buttonText,
  fixedSelectedUsers,
  onSuccess,
}) => {
  const { t } = useTranslation(locales);
  const toast = useToast();

  const orgPointsConfig = useSelector(authSelectors.selectOrgPointsConfig);

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAssigningPoints, setIsAssigningPoints] = useState(false);

  const {
    register, handleSubmit, formState: { errors }, setValue, reset,
  } = useForm<UpdatePointsData>({
    resolver: zodResolver(schema),
    defaultValues: {
      userIds: (fixedSelectedUsers || []).map(user => user.fullId),
      points: 10,
    },
  });

  useEffect(() => {
    if (!isOpen) {
      reset();
      setSelectedUsers([]);
    }
  }, [isOpen, reset]);

  useEffect(() => {
    if (fixedSelectedUsers) {
      setSelectedUsers(fixedSelectedUsers);
      setValue('userIds', fixedSelectedUsers.map(user => user.fullId));
    }
  }, [fixedSelectedUsers, setValue]);

  const handleUserSelect = (user: User) => {
    setSelectedUsers([...selectedUsers, user]);
    setValue('userIds', [...selectedUsers.map(u => u.fullId), user.fullId]);
  };

  const handleUserRemove = (userId: string) => {
    setSelectedUsers(selectedUsers.filter(user => user.fullId !== userId));
    setValue('userIds', selectedUsers.filter(user => user.fullId !== userId).map(u => u.fullId));
  };

  const onSubmitHandler = async (data: UpdatePointsData) => {
    try {
      setIsAssigningPoints(true);
      await Api.req.post('/users/orgpoints/assign', data);
      toast.success(t('Points assigned successfully'));
      onSuccess?.();
      setIsAssigningPoints(false);
      onClose();
    } catch (error) {
      toast.error(error);
      setIsAssigningPoints(false);
    }
  };

  if (!orgPointsConfig.enabled) return null;

  return (
    <>
      <Button onClick={onOpen} {...buttonProps}>
        {buttonText || t('Assign Points')}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Assign Points')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack as="form" spacing={4} onSubmit={handleSubmit(onSubmitHandler)}>
              <FormControl isInvalid={!!errors.userIds}>
                <FormLabel>{t('Select Users')}</FormLabel>
                {fixedSelectedUsers ? (
                  <Wrap>
                    {selectedUsers.map((user) => (
                      <WrapItem key={user.fullId}>
                        <UserPill user={user} />
                      </WrapItem>
                    ))}
                  </Wrap>
                ) : (
                  <UserSearchInput
                    selectedUsers={selectedUsers}
                    onUserSelect={handleUserSelect}
                    onUserRemove={handleUserRemove}
                    placeholder={t('Search users to update points...')}
                    multiple
                  />
                )}
              </FormControl>

              <FormControl>
                <FormLabel>{t('Points')}</FormLabel>
                <NumberInput>
                  <NumberInputField {...register('points', { valueAsNumber: true })} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel>{t('Reason (Optional)')}</FormLabel>
                <Textarea {...register('description')} />
              </FormControl>

              <Button type="submit" variant="primary" width="full" isLoading={isAssigningPoints}>
                {t('Assign Points')}
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
