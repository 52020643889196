import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Text,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  Button,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  ButtonGroup,
  Stack,
  Spinner,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import {
  ArrowUpRight, ArrowDownLeft, Plus,
} from 'lucide-react';

import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';
import useTranslation from 'hooks/useTranslation';

import PageHeader from 'components/PageHeader';
import TransferButton from 'components/TransferButton';

import locales from '../i18n';
import { BANK_BOX_TYPES } from '../../../constants';
import { TransferPoints } from './transfer';

export const OrgPoints: React.FC = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  const params = useParams<{ username: string }>();

  const userId = useSelector(userSelectors.getByUsername(params.username));
  const fullId = useSelector(userSelectors.getFullIdByUsername(params.username));
  const orgPoints = useSelector(userSelectors.getOrgPoints(userId));

  const [refetching, setRefetching] = useState(false);

  const refreshUser = useCallback(async () => {
    setRefetching(true);
    await dispatch(userActions.fetchByUsername(params.username, true));
    setRefetching(false);
  }, [params.username, dispatch]);

  if (!orgPoints) return null;

  return (
    <Box p={4}>
      <PageHeader ml={2} mb={2} title={orgPoints.pluralName} />
      <Text fontSize="sm" color="gray.500" mb={6}>{orgPoints.description}</Text>

      <VStack spacing={8} align="stretch">
        {/* Points Display */}
        <Stack>
          <Box bg="white" p={6} borderRadius="lg" shadow="md">
            <Stat>
              <StatLabel color="gray.600">
                {t('Your {{pluralName}} Balance', { pluralName: orgPoints.pluralName })}
              </StatLabel>
              <StatNumber fontSize="4xl" fontWeight="bold" color="black">
                {refetching
                  ? <Spinner size="sm" />
                  : orgPoints.points}
              </StatNumber>
            </Stat>
          </Box>

          {/* Transfer Button */}
          <ButtonGroup size="sm">
            {orgPoints.canTransferBetweenUsers && (
              <TransferPoints
                pluralName={orgPoints.pluralName}
                organizationId={fullId}
                onSuccess={refreshUser}
              />
            )}
            {orgPoints.canBuyWithSades && (
              <TransferButton
                to={{ type: BANK_BOX_TYPES.USER, id: userId }}
                component={<Button leftIcon={<Plus size={18} />}>{t('Buy with Sades')}</Button>}
                info={t('You\'ll get {{points}} {{pluralName}} for every Sade you transfer', {
                  points: orgPoints.sadesRate,
                  pluralName: orgPoints.pluralName,
                })}
                afterTransfer={refreshUser}
              />
            )}
          </ButtonGroup>
        </Stack>

        {/* Transaction Ledger */}
        {orgPoints.transactions?.length > 0 && (
          <Box bg="white" py={6} borderRadius="lg" shadow="sm">
            <Text fontSize="xl" fontWeight="bold" mb={4}>{t('Transaction History')}</Text>
            <Box overflowX="auto">
              <Table variant="simple" size={{ base: 'sm', md: 'md' }}>
                <Thead>
                  <Tr>
                    <Th>{t('Date')}</Th>
                    <Th>{t('Type')}</Th>
                    <Th display={{ base: 'none', md: 'table-cell' }}>{t('User')}</Th>
                    <Th display={{ base: 'none', md: 'table-cell' }}>{t('Description')}</Th>
                    <Th isNumeric>{t('Amount')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {orgPoints.transactions?.map((tx: any) => (
                    <Tr key={tx.id}>
                      <Td>{new Date(tx.timestamp).toLocaleDateString()}</Td>
                      <Td>
                        <HStack>
                          {tx.type === 'received' ? (
                            <Badge colorScheme="green" display="flex" alignItems="center" gap={1}>
                              <ArrowDownLeft size={14} />
                              <Text display={{ base: 'none', sm: 'block' }}>{t('Received')}</Text>
                            </Badge>
                          ) : (
                            <Badge colorScheme="orange" display="flex" alignItems="center" gap={1}>
                              <ArrowUpRight size={14} />
                              <Text display={{ base: 'none', sm: 'block' }}>{t('Sent')}</Text>
                            </Badge>
                          )}
                        </HStack>
                      </Td>
                      <Td display={{ base: 'none', md: 'table-cell' }}>{tx.type === 'received' ? tx.fromUser : tx.toUser}</Td>
                      <Td display={{ base: 'none', md: 'table-cell' }}>{tx.description}</Td>
                      <Td isNumeric fontWeight="medium" color={tx.type === 'received' ? 'green.500' : 'orange.500'}>
                        {tx.type === 'received' ? '+' : '-'}
                        {tx.amount}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
        )}
      </VStack>
    </Box>
  );
};
