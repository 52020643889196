import { formAnatomy as parts } from '@chakra-ui/anatomy';
import { PartsStyleFunction } from '@chakra-ui/react';

const baseStyle: PartsStyleFunction<typeof parts> = () => ({
  helperText: {
    mb: 2, mt: -1, lineHeight: '1', color: 'gray.500',
  },
});

export default { baseStyle };
