import React, { useState, useRef, KeyboardEvent } from 'react';
import {
  Box,
  Input,
  InputGroup,
  List,
  ListItem,
  Text,
  Flex,
  useOutsideClick,
  Avatar,
  Spinner,
  InputProps,
} from '@chakra-ui/react';
import { useDebouncedCallback } from 'use-debounce';

import Api from 'state/api';

import { UserPill } from 'components/user-pill';

export interface User {
  id: number;
  fullId: string;
  displayname: string;
  username: string;
  avatar: {
    default: string;
    '300w': {
      jpeg: string;
      webp: string;
    };
    '150w': {
      jpeg: string;
      webp: string;
    };
    '50w': {
      jpeg: string;
      webp: string;
    };
    '32w': {
      jpeg: string;
      webp: string;
    };
  };
}

interface UserSearchInputProps {
  selectedUsers: User[];
  onUserSelect: (user: User) => void;
  onUserRemove: (userId: string) => void;
  multiple?: boolean;
  placeholder?: string;
  inputProps?: InputProps;
}

export const UserSearchInput: React.FC<UserSearchInputProps> = ({
  selectedUsers,
  onUserSelect,
  onUserRemove,
  multiple = false,
  placeholder = 'Search users...',
  inputProps,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState<User[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  useOutsideClick({
    ref: wrapperRef,
    handler: () => {
      setIsOpen(false);
      setSelectedIndex(-1);
    },
  });

  const searchUsers = async (query: string) => {
    try {
      setIsLoading(true);
      const { data: results } = await Api.req.get<User[]>(`/users/search/${query}`);
      const filteredResults = results.filter(
        (user) => !selectedUsers.some((selected) => selected.fullId === user.fullId),
      );
      setSuggestions(filteredResults);
      setIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const [debouncedSearchUsers] = useDebouncedCallback(searchUsers, 1000);

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
    setSelectedIndex(-1);

    const parsedQuery = query.trim().toLowerCase();
    if (parsedQuery.length > 2) {
      debouncedSearchUsers(parsedQuery);
    } else {
      setSuggestions([]);
      setIsOpen(false);
    }
  };

  const handleSelect = (user: User) => {
    onUserSelect(user);
    setSearchQuery('');
    setSuggestions([]);
    setIsOpen(false);
    setSelectedIndex(-1);
    inputRef.current?.focus();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && searchQuery === '' && selectedUsers.length > 0) {
      onUserRemove(selectedUsers[selectedUsers.length - 1].fullId);
    } else if (isOpen && suggestions.length > 0) {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex(prev => (prev < suggestions.length - 1 ? prev + 1 : prev));
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex(prev => (prev > 0 ? prev - 1 : prev));
          break;
        case 'Enter':
          e.preventDefault();
          if (selectedIndex >= 0) {
            handleSelect(suggestions[selectedIndex]);
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <Box position="relative" ref={wrapperRef}>
      <InputGroup>
        <Flex
          wrap="wrap"
          gap={1}
          p={2}
          border={(multiple || selectedUsers.length === 0) ? '1px solid' : 'none'}
          borderColor="gray.200"
          borderRadius="md"
          w="full"
          _hover={{
            borderColor: 'gray.300',
          }}
          _focusWithin={{
            borderColor: 'black',
          }}
          alignItems="flex-start"
        >
          {selectedUsers.map((user) => (
            <UserPill
              key={user.fullId}
              user={user}
              onRemove={onUserRemove}
            />
          ))}
          {(multiple || selectedUsers.length === 0) && (
            <Input
              ref={inputRef}
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={selectedUsers.length === 0 ? placeholder : ''}
              border="none"
              _focus={{
                boxShadow: 'none',
              }}
              flex={1}
              p={0}
              minW="100px"
              height="1.5rem"
              {...inputProps}
            />
          )}
          {isLoading && (
            <Spinner size="sm" color="gray.400" position="absolute" right="3" top="12px" />
          )}
        </Flex>
      </InputGroup>

      {isOpen && suggestions.length > 0 && (
        <List
          position="absolute"
          zIndex={1}
          width="100%"
          bg="white"
          boxShadow="lg"
          borderRadius="md"
          mt={1}
          maxH="200px"
          overflowY="auto"
        >
          {suggestions.map((user, index) => (
            <ListItem
              key={`suggestion-${user.fullId}`}
              px={4}
              py={2}
              cursor="pointer"
              bg={index === selectedIndex ? 'gray.100' : 'transparent'}
              _hover={{ bg: 'gray.100' }}
              onClick={() => handleSelect(user)}
              display="flex"
              alignItems="center"
            >
              <Avatar
                src={user.avatar.default}
                size="xs"
                name={user.displayname}
                mr={2}
              />
              <Box>
                <Text fontWeight="medium">{user.displayname}</Text>
                <Text fontSize="sm" color="gray.500">
                  @
                  {user.username}
                </Text>
              </Box>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};
