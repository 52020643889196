import { createSelector } from 'reselect';

import { BADGES } from '../../constants';

// Input selectors

export const selectOnline = state => state.users.online;

export const selectData = state => state.users.data;

export const selectSuggestions = state => state.users.suggestions;

//

export const selectById = createSelector(
  selectData,
  (_, id) => id,
  (data, id) => data[id],
);

export const getById = id => ({ users: state }) => {
  if (!id) return null;
  const user = state.data[id];
  if (!user) {
    return {
      id,
      username: 'loading...',
      displayname: 'loading...',
      loading: true,
      avatar: '',
    };
  }

  const online = state.online.includes(id);
  return {
    ...user,
    online,
  };
};

export const isLoaded = userId => state => userId && !!state.users.data[userId];

export const getByUsername = username => ({ users }) => {
  const userId = users.usernames[username];
  if (!userId) return null;

  const user = users.data[userId];
  if (!user) return null;
  return user.id;
};

export const getFullIdByUsername = username => ({ users }) => {
  const userId = users.usernames[username];
  if (!userId) return null;

  const user = users.data[userId];
  if (!user) return null;
  return user.fullId;
};

export const getBatchByIds = (ids) => ({ users: state }) => {
  const result = {};
  ids.forEach((id) => {
    result[id] = getById(id)({ users: state });
  });

  return result;
};

export const getListByIds = ids => ({ users: state }) => (
  ids.map(id => getById(id)({ users: state }))
);

export const isBlockingMe = userId => (state) => {
  const user = getById(userId)(state);
  if (!user) return null;
  return user.blocked;
};

export const isOrganization = userId => (state) => {
  const user = getById(userId)(state);
  if (!user) return null;
  return user.isOrganization;
};

export const isOrgPointsEnabled = userId => (state) => {
  const user = getById(userId)(state);
  if (!user) return null;
  return user.organizationPoints?.enabled;
};

export const getOrgPoints = userId => (state) => {
  const user = getById(userId)(state);
  if (!user) return null;
  return user.organizationPoints;
};

export const getUsername = id => (state) => {
  const user = getById(id)(state);
  if (!user) return null;
  return user.username;
};

export const getDisplayName = id => (state) => {
  const user = getById(id)(state);
  if (!user) return null;
  return user.displayname;
};

export const selectDisplayName = createSelector(
  selectById,
  user => user?.displayname || null,
);

export const isActive = id => (state) => {
  const user = getById(id)(state);
  if (!user || (!user.suspended && !user.banned)) return true;
  return false;
};

export const isSuspended = id => (state) => {
  const user = getById(id)(state);
  if (!user || !user.suspended) return false;
  return true;
};

export const isBanned = id => (state) => {
  const user = getById(id)(state);
  if (!user || !user.banned) return false;
  return true;
};

export const getCover = id => (state) => {
  const user = getById(id)(state);
  if (!user) return null;

  return !user || user.loading || !user.cover || !Object.keys(user.cover).length ? null : user.cover['768w'].jpeg;
};

export const getRegdate = id => ({ users }) => {
  const user = users.data[id];
  if (!user) return null;
  return user.regdate;
};

export const getJob = id => ({ users }) => {
  const user = users.data[id];
  if (!user) return null;
  return user.job;
};

export const getLastLogin = id => ({ users }) => {
  const user = users.data[id];
  if (!user) return null;
  return user.lastLogin;
};

export const getRelationships = (id, onlyOwner = false) => ({ users }) => {
  const user = users.data[id];
  if (!user) return null;

  if (onlyOwner) return (user.relationships || []).filter(r => r.user.id === id);
  return user.relationships;
};

export const getSubscriptions = (id) => ({ users }) => {
  const user = users.data[id];
  if (!user) return null;

  return user.subscriptions;
};

export const getAboutMe = id => ({ users }) => {
  const user = users.data[id];
  if (!user) return null;
  return user.aboutMe;
};

export const getAge = id => ({ users }) => {
  const user = users.data[id];
  if (!user) return null;
  return user.age;
};

export const getGender = id => ({ users }) => {
  const user = users.data[id];
  if (!user) return null;
  return user.gender;
};

// REMOVE!!!
export const getRole = id => ({ users }) => {
  const user = users.data[id];
  if (!user || !user.role) return null;
  return user.role.text;
};

export const getOrientation = id => ({ users }) => {
  const user = users.data[id];
  if (!user || !user.orientation) return null;
  return user.orientation.text;
};
//

export const getTags = id => (state) => {
  const user = state.users.data[id];
  if (!user) return [];
  return user.tags || [];
};

export const getTagsDescription = (id) => (state) => {
  const user = state.users.data[id];
  if (!user) return [];
  return user.tagsDescription || [];
};

export const getPronoun = id => ({ users }) => {
  const user = users.data[id];
  if (!user) return null;
  return user.pronoun;
};

export const getCityName = id => ({ users }) => {
  const user = users.data[id];
  if (!user || !user.city) return null;

  return user.city.name;
};

export const getRegionName = id => ({ users }) => {
  const user = users.data[id];
  if (!user || !user.region) return null;

  return user.region.name;
};

export const getCountryName = id => ({ users }) => {
  const user = users.data[id];
  if (!user || !user.country) return null;

  return user.country.name;
};

export const getKnown = id => ({ users }) => {
  const user = users.data[id];
  if (!user) return null;
  return user.knowedCount;
};

export const getEventCount = id => ({ users }) => {
  const user = users.data[id];
  if (!user) return null;
  return user.eventCount;
};

export const getFollowers = id => ({ users }) => {
  const user = users.data[id];
  if (!user) return null;
  return user.followersCount;
};

export const getFollowing = id => ({ users }) => {
  const user = users.data[id];
  if (!user) return null;
  return user.followingCount;
};

export const isFollowingMe = id => (state) => {
  const user = getById(id)(state);
  if (!user) return null;
  return user.isFollowingMe;
};

export const usersToFetch = () => ({ users: state }) => (
  [...state.userFetchQueue].filter(id => !Object.keys(state.data).includes(id))
);

export const isFetching = () => ({ users: state }) => state.userFetching;

export const isFullyLoaded = userId => ({ users: state }) => {
  const user = state.data[userId];
  if (!user) return false;
  return user.fullyLoaded;
};

export const isOnline = id => ({ users: state }) => state.online.includes(id);

export const onlineCount = ids => ({ users: state }) => ids
  .filter(id => state.online.includes(id))
  .length;

export const getOnlineIdsMatching = ids => ({ users: state }) => ids
  .filter(id => state.online.includes(id));

export const getSuggestions = (suggestions, filter) => ({ users: state, channels }) => {
  const MAX_CAP = 5;

  if (suggestions && suggestions.type === 'channel') {
    const ids = channels.data.channels[suggestions.id].participants.map(p => p.userId);
    const filtered = ids
      .filter((userId) => {
        const user = state.data[userId];

        return (
          user
          && (
            user.username.toLowerCase().includes(filter.toLowerCase())
            || user.displayname.toLowerCase().includes(filter.toLowerCase())
          )
        );
      })
      .map((userId) => {
        const user = state.data[userId];
        return { userId, name: user.displayname };
      });

    const cap = Math.min(filtered.length, MAX_CAP);
    return filtered.slice(0, cap);
  }

  return [];
};

export const infoLoaded = userId => ({ users }) => {
  const user = users.data[userId];
  if (!user) return null;
  return user.infoLoaded;
};

export const getBadges = createSelector(
  selectById,
  user => ((user && user.badges) || []),
);

export const getBadgeCount = createSelector(
  getBadges,
  badges => badges.length,
);

export const getBadgesValue = createSelector(
  getBadges,
  badges => badges.reduce((acc, b) => (acc + (BADGES[b]?.value || 0)), 0),
);

export const lovenseToys = createSelector(
  selectById,
  user => user?.connections?.lovense || [],
);
